import { Carousel } from "antd";
import React, { FC, useState } from "react";
import { images } from "../../../commonConfig";
import './detailspage-image.scss'
import ReactPlayer from 'react-player'

interface DetailPageImageProps {
    restaurantData: any
}

const DetailPageImage: FC<DetailPageImageProps> = ({ restaurantData }) => {
    const [videoId, setVideoId] = useState<any>()
    const [fullScreen, setFullScreen] = useState<boolean>(false)
    const [isPlaying, setIsPlaying] = useState<any>(false)

    const handleVideoChange = (currentSlide: number) => {
        const element: any = document.getElementById(`PromotionImg${currentSlide}`);
        setVideoId(element?.id)
    }
    const openFullscreen = (e: any) => {
        document.getElementById(e)?.requestFullscreen();
        setFullScreen(true)
    }

    const exitFullScreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
        setFullScreen(false)
    }

    const onVideoStart = (e: any) => {
        if (e === videoId) {
            setIsPlaying(true)
        }
    }

    const onVideoPause = (e: any) => {
        setIsPlaying(false)
    }
    return (
        <>  <div className="image-section-page">
            <Carousel dots={true}
                autoplay={isPlaying ? false : true}
                autoplaySpeed={5000}
                effect={isPlaying ? 'scrollx' : 'scrollx'}
                afterChange={(currentSlide: number) => handleVideoChange(currentSlide)}
            >
                <div style={{ display: "flex", position: "relative" }}>
                    <img id="PromotionImg0"
                        src={
                            restaurantData?.bannerImage
                                ? restaurantData?.bannerImage
                                : images.IMG_DEFAULT_IMAGE
                        }
                        alt="icon"
                        className="res-image"
                        onClick={() => fullScreen ? exitFullScreen() : openFullscreen("PromotionImg0")}
                    /></div>
                {restaurantData?.promotions?.sort((a: any, b: any) => a.id.localeCompare(b.id, 'en', { numeric: true }))?.map((arr: any) => {
                    return (
                        <div style={{ display: "flex" }}>
                            {arr.type === 'image' ?
                                <img id={arr.id} src={arr.url} alt="icon" className="res-image" onClick={() => fullScreen ? exitFullScreen() : openFullscreen(arr.id)} />
                                : <div className="cover-video">
                                    <ReactPlayer width='100%' height='inherit' id={arr.id} playsinline controls
                                        onStart={() => onVideoStart(arr.id)} onPause={() => onVideoPause(arr.id)}
                                        url={arr.url} playing={arr.id === videoId && isPlaying} />
                                </div>}
                        </div>
                    )
                })
                }
            </Carousel>
            {/* <img src={images.HOME_LOGO} alt='icon' className='homelogo-image' /> */}
        </div>

        </>

    )
}
export default DetailPageImage
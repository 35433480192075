import React, { FC, useState, useEffect } from "react";
import { GoDotFill } from "react-icons/go";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import Carousel from "react-bootstrap/Carousel";
import moment from "moment";
import { IoChevronDownSharp, IoChevronUpSharp } from "react-icons/io5";
import { useLocation } from "react-router-dom";

import Footer from "../../components/molecules/Footer/Footer";
import MenuItemTab from "../../components/organisms/MenuItemTab/MenuItemTab";
import { images } from "../../commonConfig/index";
import { db } from "../../firebase/firebase";
import { taxProps } from "../../interfaces";

import "bootstrap/dist/css/bootstrap.css";
import "./DetailspageTab.scss";
import "react-loading-skeleton/dist/skeleton.css";
import { Rings } from "react-loader-spinner";
import { collection, getDocs, query, where } from "firebase/firestore";
// import DetailPageImage from "./DetailPageImage/DetailPageImage";
import DetailPageImage from "./DetailPageImage/DetailPageImage";

// var uniqueItemArray: any;
var encodedAddress = '';

const DetailsPageTab: FC = () => {
  const props = useLocation().state;

  const [resSet, setResSet] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<any>();
  const [daysOpen, setDaysOpen] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<string>();
  const [fix, setFix] = useState<boolean>(false);
  const [fix1, setFix1] = useState<boolean>(false);
  const [category, setCategory] = useState<any>([]);
  const [getCatLodder, setGetCatLodder] = useState<boolean>(true);
  const [categoryIndex, setCategoryIndex] = useState<number>(0);
  const [itemData, setItemData] = useState<any>();
  const [cardIndex, setCardIndex] = useState<number>(0);
  const [itemLoadder, setItemLoadder] = useState<boolean>(true);
  const [carouselVisible, setCarouselVisible] = useState<boolean>(true);
  const [tagStartTime, setTagStartTime] = useState<any>();
  const [tagEndTime, setTagEndTime] = useState<any>();
  const [localTax, setLocalTax] = useState<number>();
  const [serviceCharge, setServiceCharge] = useState<number>();
  const [showSearch, setShowSearch] = useState(false);
  const [new1, setNew1] = useState<any>([]);
  const [sponsorData, setSponsorData] = useState<any>();
  // const [subCatData, setSubCatData] = useState<any[]>([]);
  const [menuItemRatings, setMenuItemRatings] = useState<any[]>([])
  const [uniqueItemArray, setUniqueItemArray] = useState<any[]>([])

  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const getTimings = (daysData: any, selectedDay: any) => {
    const time = daysData?.find((day: any) => day.days.includes(selectedDay));
    if (time) {
      return time;
    } else {
      return false;
    }
  };

  let allTempTags: any = [];
  if (props?.resData) {
    props?.resData?.days?.map((ele: any) => {
      ele.fields.map((ele: any) =>
        ele.tags.map((ele: any) => allTempTags.push(ele))
      );
    });
    allTempTags.filter(
      (item: any, index: any) => allTempTags.indexOf(item) === index
    );
  }

  let allData: any = [];
  if (props?.resData) {
    props?.resData?.days?.map((ele: any) => {
      ele.fields.map((ele: any) => allData.push(ele));
    });
  }


  useEffect(() => {
    if (props?.selectedTag) {
      setSelectedTag(props?.selectedTag);
    }
  }, [props?.selectedTag]);

  const DropDown = () => {
    setDaysOpen(!daysOpen);
  };

  const getOpenStatus = () => {
    if (props.resData?.timezone && props.resData?.hoursOperation) {
      const { timezone, hoursOperation } = props.resData;
      const today = moment(new Date()).tz(timezone);
      const selectedDateObj = getTimings(hoursOperation, today.format("dddd"));
      setSelectedDay(selectedDateObj);

      if (selectedDateObj?.openTime && selectedDateObj?.closeTime) {
        let startTime: any = parseFloat(moment(selectedDateObj.openTime, ["hh:mm a"], timezone).format("HH.mm"));
        let endTime: any = parseFloat(moment(selectedDateObj.closeTime, ["hh:mm a"], timezone).format("HH.mm"));
        let currentTime: any = parseFloat(moment(new Date()).tz(timezone).format("HH.mm"));

        endTime += startTime > endTime ? 24 : 0;

        if (currentTime >= startTime && currentTime <= endTime) {
          setResSet(true);
        } else {
          const yesterday = today.clone().subtract(1, 'days');
          const yesterdayDateObj = getTimings(hoursOperation, yesterday.format("dddd"));
          if (yesterdayDateObj?.openTime && yesterdayDateObj?.closeTime) {
            let yesterdayStartTime: any = parseFloat(moment(yesterdayDateObj.openTime, ["hh:mm a"], timezone).format("HH.mm"));
            let yesterdayEndTime: any = parseFloat(moment(yesterdayDateObj.closeTime, ["hh:mm a"], timezone).format("HH.mm"));
            setResSet(yesterdayStartTime > yesterdayEndTime && yesterdayEndTime >= currentTime)
          } else {
            setResSet(false)
          }
        }
      }
    }

  };

  useEffect(() => {
    if (allData.length > 0) {
      const startTime = allData?.find((obj: any) =>
        obj?.tags?.includes(selectedTag || allTempTags[0])
      ).start_time;
      setTagStartTime(startTime);
      const endTime = allData?.find((obj: any) =>
        obj?.tags?.includes(selectedTag || allTempTags[0])
      ).end_time;
      setTagEndTime(endTime);
    }
  }, [allData]);

  const setFixedLeft = () => {
    if (window.scrollY >= 220) {
      setDaysOpen(false);
    }
    if (window.scrollY >= 460) {
      setFix1(true);
    } else {
      setFix1(false);
    }
  };

  const handleScroll = () => {
    if (window.innerWidth >= 501 && window.innerWidth <= 650) {
      const index = Math.floor(window.scrollY / 400);
      if (index >= itemData?.length - 1) {
        setCardIndex(itemData?.length - 1);
      } else {
        setCardIndex(index);
      }
    } else if (window.innerWidth >= 851 && window.innerWidth <= 1020) {
      const index = Math.floor(window.scrollY / 450);
      if (index >= itemData?.length - 1) {
        setCardIndex(itemData?.length - 1);
      } else {
        setCardIndex(index);
      }
    } else if (window.innerWidth >= 1020 && window.innerWidth <= 1400) {
      const index = Math.floor(window.scrollY / 500);
      if (index >= itemData?.length - 1) {
        setCardIndex(itemData?.length - 1);
      } else {
        setCardIndex(index);
      }
    } else {
      const index = Math.floor(window.scrollY / 550);
      if (index >= itemData?.length - 1) {
        setCardIndex(itemData?.length - 1);
      } else {
        setCardIndex(index);
      }
    }
  };

  const scrollFoter = () => {
    var element: any = document.querySelector("#footer");
    var position = element?.getBoundingClientRect();
    if (position?.top < window.innerHeight - 250 && position.bottom >= 0) {
      setFix(true);
      setFix1(false);
    } else {
      setFix(false);
    }
  };

  useEffect(() => {
    getOpenStatus();
    const address = `${props?.resData.add1} ${props?.resData.city} ${props?.resData.state}`;
    encodedAddress = encodeURIComponent(address);
  }, [props?.resData]);

  window.addEventListener("scroll", setFixedLeft);
  window.addEventListener("scroll", handleScroll);
  window.addEventListener("scroll", scrollFoter);

  const scrollFunction = (index: any) => {
    if (window.innerWidth >= 651 && window.innerWidth <= 850) {
      window.scrollTo({ behavior: "smooth", top: 456 * index });
    } else if (window.innerWidth >= 851 && window.innerWidth <= 1020) {
      window.scrollTo({ behavior: "smooth", top: 506 * index });
    } else {
      window.scrollTo({ behavior: "smooth", top: 840 * index });
    }
  };
  const getMenuFeedbackData = async () => {
    var menufeedbackRating: any = []
    var feedbackData: any
    await db
      .collection("Feedback")
      .where("restaurantId", "==", props?.resId)
      .get()
      .then(async (snapshot) => {
        snapshot.docs.map((doc) => {
          feedbackData = doc.data()?.menuItemRatings
          if (feedbackData && feedbackData.length) {
            menufeedbackRating = [...menufeedbackRating, ...feedbackData]
          }
        })
        setMenuItemRatings(menufeedbackRating)
      }).catch((err) => console.log(err));
    // setLoader(false);
    setItemLoadder(false);
  }


  const getCategoryData = async (itemRatings: any) => {
    if (props?.resId) {
      setGetCatLodder(true);
      await db
        .collection(`Restaurant/${props?.resId}/Category`)
        .where(
          "menuType",
          "array-contains",
          selectedTag ? selectedTag : props?.selectedTag
        )
        .where("categoryStatus", "==", true)
        .orderBy("categoryIndex")
        .get()
        .then(async (querySnapshot: any) => {
          const threadsQueried = await querySnapshot.docs.map(
            (documentSnapshot: any) => {
              return {
                ...documentSnapshot.data(),
              };
            }
          );
          setCategory(threadsQueried);
          setItemLoadder(true);
          const index =
            threadsQueried[categoryIndex] === undefined
              ? 0
              : categoryIndex;
          if (threadsQueried.length !== 0) {
            const subCategory: any[] = [];
            await db
              .collection(`Restaurant/${props?.resId}/SubCategory`)
              // .where("category", "==", threadsQueried[0]?.name)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc: any) => {
                  subCategory.push(doc.data());
                });
                setNew1(subCategory);
              });
            const ItemData = props?.menuItem?.filter((obj: any) =>
              obj.category_tag?.includes(threadsQueried[index]?.name)
            );
            getUniqueItemData(ItemData, subCategory, threadsQueried, index, itemRatings);
            setItemData(ItemData);
          } else {
            setItemData([]);
          }
          setItemLoadder(false);
        });
      setGetCatLodder(false);
    }
  };

  const getUniqueItemData = (items: any, subCat: any, threadsQueried: any, index: any, itemRatings: any) => {
    const categoryName =
      threadsQueried[index]?.name === undefined
        ? props.ele.category
        : threadsQueried[index]?.name;
    subCat = subCat.filter((obj: any) => obj.category === categoryName);
    var ItemDataCopy = [...items];
    if (items.length && subCat.length) {
      items.map((item: any, index: any) => {
        if (item?.subCategories?.length >= 0) {
          var data = subCat.filter((sub: any) =>
            item.subCategories?.includes(sub.name)
          );
          if (data.length > 0) {
            ItemDataCopy.splice(index, 1, data[0]);
          }
        }
      });
    }
    if (ItemDataCopy && ItemDataCopy.length) {
      var mergedData = [...ItemDataCopy, ...subCat];
      var uniqueSet = new Set(mergedData);
      var tempUniqueArray = uniqueItemArray
      tempUniqueArray = Array.from(uniqueSet);
      var mergedArray = tempUniqueArray.map((item: any) => {
        var matchingItem = itemRatings.find((element: any) => element.id === item.id);
        return { ...item, ...matchingItem };
      });
      setUniqueItemArray(mergedArray)
      // return uniqueItemArray;
    } else {
      // uniqueItemArray = [];
      setUniqueItemArray([])
      // return uniqueItemArray;
    }
  };

  const getSponsorData = async () => {
    const sponsorsRef = collection(db, `Restaurant/${props?.resId}/Sponsors`);
    const sponsorQuery = query(
      sponsorsRef,
      where("sponser_hide_status", "==", false)
    );
    const sponsorsShot = await getDocs(sponsorQuery);
    const sponsorsList = sponsorsShot.docs.map((doc) => doc.data());
    setSponsorData(sponsorsList);
  };

  const getResData = async (resId:any) => {
    await getMenuFeedbackData()
    // await getCategoryData();
    await getSponsorData();
  }

  useEffect(() => {
    getResData(props?.resId)
  }, [props?.resId]);

  useEffect(() => {
    getCategoryData(menuItemRatings);
    if (category[categoryIndex] === undefined) {
      setCategoryIndex(props.categoryIndex);
    } else {
      setCategoryIndex(0);
    }
  }, [selectedTag, menuItemRatings]);

  const getItemsData = async (index: any, itemRatings: any) => {
    if (!getCatLodder) {
      setItemLoadder(true);
      if (category.length !== 0) {
        const subCategory: any[] = [];
        await db
          .collection(`Restaurant/${props?.resId}/SubCategory`)
          // .where("category", "==", category[cardIndex]?.name)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc: any) => {
              subCategory.push(doc.data());
            });
            setNew1(subCategory);
          });
        const ItemData = await props?.menuItem?.filter((obj: any) =>
          obj.category_tag.includes(category[index]?.name)
        );
        getUniqueItemData(ItemData, subCategory, category, index, itemRatings);
        setItemData(ItemData);
      } else {
        setItemData([]);
      }
      setCarouselVisible(true);
      setItemLoadder(false);
    }
  };

  useEffect(() => {
    getItemsData(categoryIndex, menuItemRatings);
  }, [ categoryIndex, menuItemRatings]);

  const handleCategoryNext = () => {
    if (categoryIndex < category.length - 1) {
      setCarouselVisible(false);
      setCategoryIndex(categoryIndex + 1);
    } else if (categoryIndex === category.length - 1) {
      setCategoryIndex(0);
    }
  };

  const handleCategoryPrev = () => {
    if (categoryIndex != 0) {
      setCarouselVisible(false);
      setCategoryIndex(categoryIndex - 1);
    } else {
      setCategoryIndex(category.length - 1);
    }
  };
  const taxProps: taxProps = {
    localTax,
    serviceCharge,
  };
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ behavior: "smooth", top: 576 * props?.index + 275 });
    }, 1000);
  }, [props?.index]);

  useEffect(() => {
    setSelectedTag(props.selectedTag)
  }, [])


  const handleDisplayHours = () => {
    var element: any
    const today = moment(new Date()).tz(props.resData?.timezone).format("dddd");
    const currentTime = moment.tz(props.resData?.timezone).format('hh:mm A');
    if (selectedTag === undefined) {
      var array: any = []
      props.tags[0].hoursOperation.map((hours: any) => {
        if (hours.days.includes(today)) {
          const current = moment.tz(currentTime, "hh:mm A", props.resData?.timezone);
          var open: any = moment.tz(hours.openTime, "hh:mm A", props.resData?.timezone);
          var close: any = moment.tz(hours.closeTime, "hh:mm A", props.resData?.timezone);
          if (current > open && current < close) {
            open = moment(open).format('hh:mm a')
            close = moment(close).format('hh:mm a')
            array.push(`${open} - ${close}`)
          }
        }
      });
      element = <span className="menu-time">
        {array.length >= 1 ? array : 'Closed'}
      </span>
    } else {
      var array: any = []
      var data: any = props.tags?.filter((tag: any) => tag.name === selectedTag)
      data[0].hoursOperation.map((hours: any) => {
        if (hours.days.includes(today)) {
          const current = moment.tz(currentTime, "hh:mm A", props.resData?.timezone);
          var open: any = moment.tz(hours.openTime, "hh:mm A", props.resData?.timezone);
          var close: any = moment.tz(hours.closeTime, "hh:mm A", props.resData?.timezone);
          if (current > open && current < close) {
            open = moment(open).format('hh:mm a')
            close = moment(close).format('hh:mm a')
            array.push(`${open} - ${close}`)
          }
        }
      });
      element = <span className="menu-time">
        {array.length >= 1 ? array : 'Closed'}
      </span>
    }
    return element;
  }

  return (
    <>
      {props ? (
        <div className="Menupage">
          <DetailPageImage restaurantData={props.resData} />
          <p className="border"></p>
          <div className="res-details">
            <div className="background-image">
              <img
                src={
                  props.resData.cropedLogoUrl
                    ? props.resData.cropedLogoUrl
                    : images.IMG_DEFAULT_LOGO
                }
                alt="logo"
                className="res-logo"
              />
            </div>
            <div className="res-name">
              <div className="res-name-section">
                <h1 className="name-resData">{props.resData.name}</h1>
                <div className="sub-name">
                  <span className="sub-name">{props.resData.type}</span>
                  <span className="no-content">
                    {" "}
                    <GoDotFill size={8} />{" "}
                  </span>
                  <a className="a-href" rel="noreferrer" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`}>{props.resData.city}</a>
                  {/* <span className="sub-name">{props.resData.city}</span> */}
                </div>
              </div>
              <div
                style={{
                  marginTop: "-8px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="res-status-section" onClick={DropDown}>
                  {resSet ? (
                    <>
                      <div className="res-open-status">Open now</div>
                      <span
                        className="color-1"
                        style={{ display: "flex", color: "#779498" }}
                      >
                        {" "}
                        <GoDotFill size={8} />{" "}
                      </span>
                      <div className="color">
                        Closes at {selectedDay?.closeTime}{" "}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="res-open-red">Closed</div>
                      <span
                        className="color-1"
                        style={{
                          display: "flex",
                          marginLeft: "-5px",
                          color: "#779498",
                        }}
                      >
                        {" "}
                        <GoDotFill size={8} />{" "}
                      </span>
                      <div className="color">
                        Open at {selectedDay?.openTime}{" "}
                      </div>
                    </>
                  )}
                  {daysOpen ? (
                    <IoChevronUpSharp
                      size={20}
                      className="icon-dropdown"
                      onClick={DropDown}
                    />
                  ) : (
                    <IoChevronDownSharp
                      size={20}
                      className="icon-dropdown"
                      onClick={DropDown}
                    />
                  )}
                </div>
                <div className="res-kitchen-time">
                  Kitchen Closes at {selectedDay?.kitchenCloseTime}
                </div>
                {selectedDay?.lastOrder ? <div className="res-kitchen-time" style={{marginTop:"5px"}}>Last Order at {selectedDay?.lastOrder}</div> : ""}

                {props.resData.dresscode && <div className="dress-code">{props.resData.dresscode}</div>}
                {props.resData.verbiage && <div className="dress-code">{props.resData.verbiage}</div>}
                {daysOpen && (
                  <div className="res-open-days" style={{ marginLeft: "25px" }}>
                    {weekDays.map((e: any) => {
                      return (
                        <>
                          <div
                            style={{
                              color: "#0d595a",
                              fontWeight: 600,
                              fontSize: "14px",
                            }}
                          >
                            {e}
                            <div
                              style={{
                                color: "#779498",
                                fontWeight: "600",
                                fontSize: "12px",
                              }}
                            >
                              {getTimings(props.resData?.hoursOperation, e)
                                ? `${getTimings(props.resData?.hoursOperation, e)
                                  .openTime
                                } - ${getTimings(props.resData?.hoursOperation, e)
                                  .closeTime
                                } `
                                : "Closed"}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <>
            {props.tags?.length > 0 ? (
              <>
                <p className="border-2"></p>
                <div className="menu-bar-main-section">
                  {props.tags.map((item: any, index: any) => (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="menu-bar-sub-section">
                        <div className="menu-bar-title-section">
                          <div className="menu-bar-index">
                            <div className="menu-bar">
                              <div
                                key={index}
                                className={
                                  selectedTag === item.name
                                    ? "menu-tag"
                                    : "menu-tag-color"
                                }
                                onClick={() => {
                                  setSelectedTag(item.name);
                                }}
                              >
                                {item.name}
                              </div>
                            </div>
                            <>{selectedTag === item.name ? handleDisplayHours() :
                              selectedTag === undefined && props.tags[0]?.name === item.name ? handleDisplayHours() : ''}</>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="no-tags"></div>
            )}
          </>
          <div className="border-3"></div>
          <div className="category-section">
            <div
              className={
                fix1
                  ? "menu-section"
                  : fix && category?.length >= 2 && itemData?.length >= 2
                    ? "menu-section-fix"
                    : "menu-section-initial"
              }
            >
              <div className="menu-title">
                {selectedTag || allTempTags[0]} Menu
              </div>
              <>
                {!getCatLodder ? (
                  <>
                    {category.length !== 0 && (
                      <>
                        <div>
                          <Carousel
                            wrap={true}
                            activeIndex={categoryIndex}
                            controls={carouselVisible ? true : false}
                            nextIcon={
                              <AiOutlineRight
                                onClick={() => handleCategoryNext()}
                                color="#026669"
                                size={20}
                              />
                            }
                            prevIcon={
                              <AiOutlineLeft
                                onClick={() => handleCategoryPrev()}
                                color="#026669"
                                size={20}
                              />
                            }
                          >
                            {category.map((item: any, idx: any) => {
                              return (
                                <Carousel.Item key={idx}>
                                  <div className="category-carousel-name">
                                    {item?.name}
                                  </div>
                                </Carousel.Item>
                              );
                            })}
                          </Carousel>
                        </div>
                        {!itemLoadder ? (
                          <>
                            {uniqueItemArray?.length > 0 ? (
                              <div className="category-menu-item-name">
                                {uniqueItemArray?.map(
                                  (item: any, index: any) => {
                                    return (
                                      <div
                                        key={index}
                                        className={
                                          cardIndex === index
                                            ? "menu-section-name-selected"
                                            : "menu-section-name"
                                        }
                                        onClick={() => scrollFunction(index)}
                                      >
                                        {item.name}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            ) : (
                              <div>
                                <p
                                  className="no-tags-menu"
                                  style={{
                                    marginTop: "25px",
                                    textAlign: "center",
                                  }}
                                >
                                  No Items Found
                                </p>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="loader">
                            <Rings color="#2bbbb7" height={80} width={100} />
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <div className="loader">
                    <Rings color="#2bbbb7" height={80} width={100} />
                  </div>
                )}
              </>
            </div>
            {!itemLoadder ? (
              <>
                {uniqueItemArray?.length > 0 ? (
                  <div className="tag-category-section" onScroll={handleScroll}>
                    {uniqueItemArray?.map((item: any, index: any) => {
                      if (sponsorData && sponsorData.length) {
                        sponsorData.map((ads: any) =>
                          ads.item_name === item.name
                            ? (item = {
                              ...item,
                              itemSponsorBannerUrl: ads.sponser_media,
                              sponser_url: ads.sponser_url,
                            })
                            : ""
                        );
                      }
                      return (
                        <>
                          <div id={item.name} key={index}>
                            <MenuItemTab
                              key={index}
                              item={item}
                              index={index}
                              data={props}
                              taxProps={taxProps}
                              sponsorData={sponsorData}
                              menuFeedbackData={menuItemRatings}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <div
                    className="tag-category-section"
                    style={{ display: "grid", justifyContent: "center" }}
                  >
                    <img src={images.NO_FOUND} alt="icon" className="no-img" />
                    <div className="no-tags-menu" style={{ width: "inherit" }}>
                      Oops, no menu items uploaded in this category!
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="loader">
                <Rings color="#2bbbb7" height={80} width={100} />
              </div>
            )}
          </div>
          <div id="footer">
            <Footer />
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              display: "flex",
              paddingTop: "100px",
            }}
          >
            <h1 className="error-message">Something went wrong!</h1>
          </div>
          <div style={{ position: "fixed", bottom: "0px" }}>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default DetailsPageTab;

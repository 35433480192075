import React, { FC, useEffect, useState } from 'react';

import { Modal } from 'antd';

import './checkbox-modal.scss'
import { images } from '../../../commonConfig';

type CheckboxModalProps = {
  open: any,
  close: any,
  mode?: string,
  header?: string,
  list?: any,
  selectedCheckbox?: any,
  setSelectedCheckbox?: React.Dispatch<React.SetStateAction<any>>
}

const CheckboxModal: FC<CheckboxModalProps> = (props) => {
  const { open, close } = props

  const [selected, setSelected] = useState<any>([])

  const handleSelectItem = (e: any) => {
    var temp: any = [...selected]
    if (e.target.checked) {
      temp = [...selected, e.target.value]
    } else {
      temp.splice(selected.indexOf(e.target.value), 1)
    }
    setSelected(temp)
    // props.setSelectedCheckbox?.(temp)
  }

  useEffect(() => {
    if(props.selectedCheckbox && props.selectedCheckbox.length){
      setSelected(props.selectedCheckbox)
    }
  }, [props.selectedCheckbox])

  const handleSave = () =>{
    props.setSelectedCheckbox?.(selected);
    close();
  }

  return (
    <Modal
      className="dwnld-modal"
      visible={open}
      onCancel={close}
    >
      <div className="dwnld-main_container" style={{ height: "430px" }}>
        {
          props.mode === 'downloadApp' ? <div className="dwnld-container">
            <div className='dwnld-close-btn'>
              <img alt='' src={images.IMG_COLOR_CROSS} onClick={close} className="dwnld-image-close" />
            </div>
            <div className="dwnld-title-preorder">Feeling Hangry?</div>
            <img alt='' src={images.IMG_ERROR} className="preOrder-empty-plate-image" />
            <div className="dwnld-txt">
              Download the visuEats app <br />to order online.
            </div>
            <div className="dwnld-btn">
              Download the app
            </div>
            {/* {isMobile === isAndroid ? <a href="https://play.google.com/store/apps/details?id=com.visueatsapp" className="btns dwnld-btn" >Download the App </a > : <a href="https://apps.apple.com/app/visueats/id6443964680" className="btns dwnld-btn" >Download the App </a >} */}
          </div>
            : <div className="dwnld-container">
              <div className="dwnld-header-container">
                <div className='dwnld-header'>{props.header}</div>
                <div className='dwnld-close-btn' style={{ marginTop: '10px' }}>
                  <img alt='' src={images.IMG_COLOR_CROSS} onClick={close} className="dwnld-image-close" />
                </div>
              </div>
              <div className="dwnld-body-container">
                {props.list.map((item: any) => {
                  return <div className='dwnld-checkbox-container'>
                    <div className="checkbox-input" style={{ width: '50px' }}>
                      <label className="container">
                        <input type="checkbox" value={item.value}
                          checked={selected.includes(item.value)}
                          onClick={(e) => { handleSelectItem(e) }} />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className='dwnld-checkbox-label'>{item.label}</div>
                  </div>
                })}
              </div>
              <div className="dwnld-btn-container">
                <button className="dwnld-btn-cancel" onClick={close}>Cancel</button>
                <button className="dwnld-btn-save" onClick={handleSave}>Save</button>
              </div>
            </div>
        }
      </div>

    </Modal>
  )
}

export default CheckboxModal
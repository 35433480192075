import firebase from 'firebase/compat/app';

import { getStorage, ref } from "firebase/storage";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import config from './config';

const Firebase = firebase.initializeApp(config);

export const Providers = {
    google: new firebase.auth.GoogleAuthProvider()
}

export const auth = firebase.auth();

export const storage = getStorage(Firebase)

export const db = firebase.firestore();

export default Firebase;
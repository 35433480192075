import React from 'react';
import { images } from '../../../commonConfig';
import Footer from '../../molecules/Footer/Footer';

const ErrorPage = () => {
  return (
    <div className="error-main-container">
    <div className="error-container">
      <h1 className="error-message">
        Oh no! <br />
        We only found crumbs.
      </h1>
      <p>
        {" "}
        We didn’t find a menu based on the URL you’ve entered. Check
        that you have the correct web address.
      </p>
      <div className="error-img">
        <img src={images.IMG_ERROR} className="error-image" alt="icon" />
      </div>
    </div>
    <div style={{ bottom: "0px" }}>
      <Footer />
    </div>
  </div>
  );
};

export default ErrorPage;
import { FC } from 'react';
import './Categorymenu.scss';
import { images } from '../../../commonConfig';

type Item = {
    data?: any,
    resData?: any,
    logoImageProps: any;
}

const CategoryMenuItem: FC<Item> = ({ data, resData, logoImageProps }) => {
    const { logoImage } = logoImageProps;
    const currentTime = new Date();

    const timestampMilliseconds = data?.isNewUpto?.seconds * 1000 + data?.isNewUpto?.nanoseconds / 1e6;
    const providedDateTime = new Date(timestampMilliseconds);
    return (
        <div className='main-part'>
            <div className='soldOut-image-container'>
                {data?.coverImageURL && (
                    <img
                        className="subcategory-image"
                        src={data.coverImageURL}
                        alt="icon"
                        width="200"
                        height="150"
                    />
                )}
                {data?.itemCoverImageUrl && (
                    <img
                        className="subcategory-image"
                        src={data.itemCoverImageUrl}
                        alt="icon"
                        width="200"
                        height="150"
                    />
                )}
                {data?.itemCoverImageUrl === '' || data?.coverImageURL == '' ?
                    <img
                        className="subcategory-image"
                        src={images.IMG_DEFAULT_LOGO}
                        alt="icon"
                        width="200"
                        height="150"
                    /> : ''
                }
                {providedDateTime > currentTime ? <div className="new_item_category">New</div> : ''}
                {data?.sold_out && <div className='sold-out-text'>{data?.sold_out && "SOLD OUT"}</div>}
                {data?.category && <div className='view-all'>View All</div>}
            </div>
            <div className='subcategory-section'>
                <span className='subcategory-name'>
                    {data.name}
                </span>
                <p className='subcategory-price'>
                    {data?.price && `$${data?.price} ${resData.currency}`}
                </p>
                {data?.rate ? <div className='feedback-text-homepage'>
                    <img src={images.IMG_STAR_DARK_FILLED} className="feedback-star-home" /><div>{data?.rate}</div>
                </div> : ""}
            </div>
        </div>
    );
};

export default CategoryMenuItem;
import React, { useEffect, useState } from "react";

import { images } from "../../../commonConfig";

import "./HomepageRestaurant.scss";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import Loader from "../../loader/loader";
import { TrackGoogleAnalyticsEvent } from "../../../googleAnalytics/GoogleAnalytics";

interface HomepageRestaurantProps {
  searchValue: any;
}

var dataPerPage = 6;
var encodedAddress = '';

const HomepageRestaurant = ({ searchValue }: HomepageRestaurantProps) => {
  const [restaurantData, setRestaurantData] = useState<any>();
  const [mergedAdRestroData, setMergedAdRestroData] = useState<any>();
  const [displayRestroData, setDisplayRestroData] = useState<any>();
  const [next, setNext] = useState(dataPerPage);

  const navigate = useNavigate();

  const getCurrentDay = (days: any, selectedDay: any) => {
    const time = days?.find((day: any) => day.days.includes(selectedDay));
    if (time) {
      return time;
    } else {
      return false;
    }
  };

  const getRestaurantTime = (obj: any) => {
    var newArray: any;
    if (obj?.timezone && obj?.hoursOperation) {
      const { timezone, hoursOperation } = obj;
      const today = moment(new Date()).tz(timezone);
      const selectedDateObj = getCurrentDay(hoursOperation, today.format("dddd"));

      if (selectedDateObj?.openTime && selectedDateObj?.closeTime) {
        let startTime: any = parseFloat(moment(selectedDateObj.openTime, ["hh:mm a"], timezone).format("HH.mm"));
        let endTime: any = parseFloat(moment(selectedDateObj.closeTime, ["hh:mm a"], timezone).format("HH.mm"));
        let currentTime: any = parseFloat(moment(new Date()).tz(timezone).format("HH.mm"));

        endTime += startTime > endTime ? 24 : 0;

        if (currentTime >= startTime && currentTime <= endTime) {
          newArray = { ...obj, openStatus: true }
        } else {
          const yesterday = today.clone().subtract(1, 'days');
          const yesterdayDateObj = getCurrentDay(hoursOperation, yesterday.format("dddd"));
          if (yesterdayDateObj?.openTime && yesterdayDateObj?.closeTime) {
            let yesterdayStartTime: any = parseFloat(moment(yesterdayDateObj.openTime, ["hh:mm a"], timezone).format("HH.mm"));
            let yesterdayEndTime: any = parseFloat(moment(yesterdayDateObj.closeTime, ["hh:mm a"], timezone).format("HH.mm"));
            newArray = { ...obj, openStatus: yesterdayStartTime > yesterdayEndTime && yesterdayEndTime >= currentTime }
          } else {
            newArray = { ...obj, openStatus: false }
          }
        }
      }
    }
    return newArray;

  };
  useEffect(() => {
    var newArr: any;
    const getRestroData = async () => {
      const restaurantCol = collection(db, "Restaurant");
      const restaurantQuery = where("status", "==", true);
      const restaurantData = query(restaurantCol, restaurantQuery);
      const restaurantSnapshot = await getDocs(restaurantData)
      const restaurantList = restaurantSnapshot.docs.map((doc: any) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const objCopy = [...restaurantList];
      var data = objCopy.map((obj) => {
        newArr = getRestaurantTime(obj);
        if (newArr === undefined) {
          return { ...obj, openStatus: false };
        }
        return newArr;
      });
      data = data.sort(
        (a, b) => +b.openStatus - +a.openStatus || a?.name?.localeCompare(b.name)
      );
     
      setRestaurantData(data);
    };
    getRestroData();
  }, []);

  useEffect(() => {
    const getSponsorData = async () => {
      const sponsorsRef = collection(db, "Sponsors");
      const sponsorQuery = query(
        sponsorsRef,
        where("hide_status", "==", false)
      );
      const sponsorsShot = await getDocs(sponsorQuery);
      const sponsorsList = sponsorsShot.docs.map((doc) => doc.data());

      var res: any = [...restaurantData];
      for (let i = 0; i < sponsorsList.length; i++) {
        if (res.length > parseInt(sponsorsList[i]?.position) * 5 + i) {
          res.splice(
            parseInt(sponsorsList[i]?.position) * 5 + i,
            0,
            sponsorsList[i]
          );
        } else {
          res.push(sponsorsList[i]);
        }
      }
      setMergedAdRestroData(res);
      setDisplayRestroData(res);
    };
    if (restaurantData && restaurantData.length) {
      getSponsorData();
    }
  }, [restaurantData]);

  useEffect(() => {
    if (searchValue !== undefined && searchValue.length > 0) {
      const filtered = mergedAdRestroData.filter((restro: any) => {
        return `${restro.name?.toLowerCase()}`.includes(
          searchValue?.toLowerCase()
        );
      });
      setDisplayRestroData(filtered);
    } else {
      setDisplayRestroData(mergedAdRestroData);
    }
  }, [searchValue]);

  const handleSelectedRestaurant = (e: any) => {
    if (e && e.media) {
      window.open(e.url, "_blank")
    } else {
      const id = e.uniqId;
      navigate(`/${id}`);
      // gaEventTracker('Restaurant Hompage', 'Restaurant Name', e.name);
      TrackGoogleAnalyticsEvent(e.name, 'Restaurant_Name', 'Restaurant_Hompage')
    }
  };

  const handleRestroImages = (e: any) => {
    var img: any;
    if (e && e.bannerImage) {
      img = (
        <img
          alt="#companyLogoImage"
          src={e.bannerImage}
          className="restaurant-img"
        />
      );
    } else if (e && e.media) {
      img = (
        <img alt="#companyLogoImage" src={e.media} className="restaurant-img" />
      );
    } else {
      img = (
        <img
          alt="#companyLogoImage"
          src={images.IMG_DEFAULT_IMAGE}
          className="restaurant-img"
        />
      );
    }
    return img;
  };

  const handleMoreRestaurants = () => {
    setNext(next + dataPerPage);
  };


  return (
    <div>
      <div>
        {displayRestroData && displayRestroData.length ? (
          <div className="restaurant-main-container">
            {displayRestroData.slice(0, next).map((restro: any) => {
               const address = `${restro.add1} ${restro.city} ${restro.state}`;
               encodedAddress = encodeURIComponent(address);
              return (
                <div key={restro.uniqId} className="restaurant-container">
                  <div onClick={() => handleSelectedRestaurant(restro)}>
                    {handleRestroImages(restro)}
                  </div>
                  {restro?.package ? (
                    <div className="restaurant-text-flex">
                      <div>
                        <div className="restaurant-title">Sponsored</div>
                      </div>
                    </div>
                  ) : (
                    <div className="restaurant-text-flex">
                      <div>
                        <div className="restro-price">
                          <div className="restaurant-title">{restro.name}</div>
                          <div className="restro-price-text">{restro?.price}</div>
                        </div>
                        <div className="restaurant-subtitle">
                          <p>
                            {restro.type !== "" ? restro.type : "Restaurant"}
                          </p>
                          <p>&#8226;</p>
                          <a className="restaurant-address-text" target="_blank" rel="noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`} >
                            {restro.city}, {restro.country}
                          </a>
                        </div>
                      </div>
                      <div>
                        {restro.openStatus ? (
                          <div className="open-now">Open now</div>
                        ) : (
                          <div className="close-now">Closed</div>
                        )}
                        <> {restro.product ?
                          <div style={{ float: "right" }}><img src={images.IMG_RESERVE} alt="" className="reserve-image" /></div>
                          : ""}
                        </>

                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            {/* {restro.reservation_settings.preOrder ? */}
            {/* <div style={{}}>vdfgdf</div> */}
            {/* : ""} */}
          </div>
        ) : searchValue?.length > 0 || searchValue !== undefined ? (
          <div>
            <div
              className="no-found-section"
              style={{ marginBottom: "50px", alignItems: "center" }}
            >
              <img src={images.NO_FOUND} alt="icon" className="no-img" />
              <div className="no-tags-menu">
                Oops, We couldn't find a match!
              </div>
              <div className="no-tags-item">
                Try searching for something else
              </div>
              {/* <div className="no-tags-go-back" onClick={() => navigate(-1)}>
              Back to {data?.resData?.name}
            </div> */}
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      {next < displayRestroData?.length && (
        <div className="view-more-container">
          <div className="view-more" onClick={() => handleMoreRestaurants()}>
            <span>View More</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomepageRestaurant;

import React, { FC, useState, useEffect } from 'react';
import './Footer.scss';
import { images } from '../../../commonConfig/index';
import { collection, getDocs, getFirestore, query } from "firebase/firestore";
import { SiTwitter } from 'react-icons/si';
import { FaTiktok, FaFacebook, FaInstagram, FaLinkedin, FaPinterest, FaYoutube } from 'react-icons/fa';

let socialLink: any

const Footer: FC = () => {
    const [socialLinks, setSocialLinks] = useState<any>([]);

    const getPlatformLink = () => {
        const userAgent = navigator.userAgent;

        // iOS detection
        if (/iPad|iPhone|iPod|Macintosh/.test(userAgent)) {
            return {
                visuknockLink: 'https://apps.apple.com/in/app/visuknock/id6480167447',
            };
        }

        // Android detection
        if (/android/i.test(userAgent)) {
            return {
                visuknockLink: 'https://play.google.com/store/apps/details?id=com.visuknock&pcampaignid=web_share',
            };
        }

        // Default links for other platforms (e.g., desktop)
        return {
            visuknockLink: 'https://play.google.com/store/apps/details?id=com.visuknock&pcampaignid=web_share',
        };
    };

    const { visuknockLink } = getPlatformLink();

    const getLink = async () => {
        const db = getFirestore()
        const q = query(collection(db, 'SocialLinks'))
        const snapshot = await getDocs(q)
        const data = snapshot.docs.map((doc) => ({
            ...doc.data(), id: doc.id
        }))

        data.map(async (elem) => {
            const workQ = query(collection(db, `SocialLinks`));
            const SocialLinks: any = await getDocs(workQ);
            const SocialLinksInfo: any = await SocialLinks.docs.map((doc: any) => ({
                ...doc.data(), id: doc.id
            }));
            setSocialLinks(SocialLinksInfo);
        })
    }
    socialLink = socialLinks[0]
    useEffect(() => {
        getLink()
    }, [])

    return (
        <div className='footer'>
            <div className='footer-title'>
                Download the App
            </div>

            <div className='app-button'>
                <a href='https://apps.apple.com/app/visueats/id6443964680' className='appstore-button' target="_blank">
                    <img src={images.APPSTORE} alt='icon' className='appstore-button' />
                </a>
                <a href='https://play.google.com/store/apps/details?id=com.visueatsapp' className='appstore-button' target="_blank">
                    <img src={images.GOOGLE} alt='icon' className='google-button' />
                </a>
            </div>

            <div className='footer-app-links'>
                <a href={visuknockLink} target='_blank' className='footer-app-link'>
                    <img src={images.IMG_FOOTER_KNOCKER} alt='footer_knocker' height={28} width={17} />
                    <span className='footer-app-link-sentence'>Become a Knocker</span>
                </a>
                <a href='https://visueats.typeform.com/to/MhEQRb2v?typeform-source=new.visueats.com' target='_blank' className='footer-app-link'>
                    <img src={images.IMG_FOOTER_PARTNER} alt='footer_knocker' height={18} width={17} />
                    <span className='footer-app-link-sentence'>Become a Partner Restaurant</span>
                </a>
            </div>

            <div className='footer-icon'>
                <a href={socialLink?.Twitter}>
                    <SiTwitter className="icon" size={30} />
                </a>
                <a href={socialLink?.Tiktok} >
                    <FaTiktok className="icon" size={30} />
                </a>
                <a href={socialLink?.Facebook} >
                    <FaFacebook className="icon" size={30} />
                </a>
                <a href={socialLink?.Instagram} >
                    <FaInstagram className="icon" size={30} />
                </a>
                <a href={socialLink?.LinkedIn} >
                    <FaLinkedin className="icon" size={30} />
                </a>
                <a href={socialLink?.Pinterest} >
                    <FaPinterest className="icon" size={30} />
                </a>
                <a href={socialLink?.Youtube} >
                    <FaYoutube className="icon" size={30} />
                </a>
            </div>

            <a href={socialLink?.Logo} className='footer-logo'>
                <img src={images.LOGO} alt='icon' className='logo-visueats' />
            </a>
            <div className='footer-rights'>
                <p>©2022 visuEats Imagery Solutions, Inc., All Rights Reserved  </p>
            </div>
        </div>
    );
};

export default Footer;
import React, { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { images } from "../../commonConfig";
import Footer from "../../components/molecules/Footer/Footer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Detailspage.scss";
import MenuItem from "../../components/molecules/Menu-Item/Menu-item";

const DetailsPage: FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const data: any = useLocation().state;
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const onChangeSearch = (e: any) => {
    e.preventDefault();
    setSearchValue(e.target.value);
  };

  return (
    <div className="main-details">
      <div>
        <div className="search-input-container">
          <div className="search-input">

            {searchValue.length > 0 && searchValue !== "" ? (
              <div className="backarrow-icon">
                <img src={images.IMG_BACKARROW} className="backArrow-icon-img" onClick={() => goBack()} />
              </div>) :
              (<div className="search-icon">
                <img src={images.IMG_SEARCH} className="search-icon-img" />
              </div>)
            }
            <input
              placeholder="Try searching “fries”"
              type="text"
              id="search-bar"
              name="search-bar"
              value={searchValue}
              onChange={(e) => onChangeSearch(e)}
              className="input-searchbar"
            />
            <button className="cross-icon btns">
              <img src={images.IMG_CROSS} className="cross-icon-img" onClick={() => { setSearchValue('') }} />
            </button>
          </div>
        </div>
        {searchValue.length > 0 && searchValue !== "" ? (
          <div className="search-result">
            <div className="search-result-text">Search results for "{searchValue}"</div>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* {searchValue.length === 0 && searchValue === "" ? (
        <div className="details-header">
          <HiOutlineChevronLeft
            className="go-back"
            size={35}
            onClick={goBack}
          />
          <div className="details-page-title">{data.item.name}</div>
        </div>
      ) : (
        ""
      )} */}
      <MenuItem data={data} index={data.index} searchString={searchValue} />
      <Footer />
    </div>
  );
};

export default DetailsPage;

import React, { useEffect, useState } from 'react';

import './feedback.scss'
import Footer from '../../../components/molecules/Footer/Footer';
import PhoneInput from 'react-phone-input-2';
import { Webmenu, getReservationById, setReservationFeedback } from '../../../commonConfig';
import FeedbackRating from './feedback-rating/feedback-rating';
import { db } from '../../../firebase/firebase';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/loader/loader';
import moment from 'moment';

var restId: any, reserveId: any;
const Feedback = () => {
    // const [reservationData, setReservationData] = useState<any>();
    const [success, setSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [overall, setOverall] = useState<any | number>(0);
    const [food, setFood] = useState<any | number>(0);
    const [drinks, setDrinks] = useState<any | number>(0);
    const [services, setServices] = useState<any | number>(0);
    const [value, setValue] = useState<any | number>(0);
    const [waitTime, setWaitTime] = useState<any | number>(0);
    const [name, setName] = useState<any | string>('');
    const [email, setEmail] = useState<any | string>('');
    const [phone, setPhone] = useState<any | string>('');
    const [waiterName, setWaiterName] = useState<any | string>('');
    const [feedback, setFeedback] = useState<any | string>('');

    const navigate = useNavigate()
    const { uniqId } = useParams();
    const scheduleReservationURL = `${Webmenu.API}${uniqId}/schedule-reservation`;

    useEffect(() => {
        let encodedUrl = window.location.href;
        let decodedUrl = decodeURIComponent(encodedUrl);

        // Now you can use the decoded URL to extract parameters
        let url = new URL(decodedUrl);
        let searchParams = new URLSearchParams(url.search);

        restId = searchParams.get('restId');
        reserveId = searchParams.get('reserveId');

        getReservationById(reserveId).then((res) => {
            // setReservationData(res);
            setName(res.first_name);
            setEmail(res.email)
            if (res.phone.includes('+')) {
                setPhone(res.phone)
            } else {
                setPhone(res.countryCode + res.phone)
            }
        })
            .catch((err) => console.log('err :>> ', err))
    }, [])

    const handleSubmit = () => {
        setLoading(true)
        const docRef = db.collection('_').doc();
        const newId = docRef.id;
        const feedbackData = {
            ratings: {
                overall,
                food,
                drinks,
                services,
                value,
                waitTime
            },
            name,
            email,
            phone,
            waiterName,
            feedback,
            restaurantId: restId,
            reservationId: reserveId,
            id: newId,
            date: moment().format('L'),
        }

        setSuccess(true)
        setReservationFeedback(feedbackData).then(() => {
            setLoading(false)
        })
            .catch((err) => console.log('err :>> ', err))
    }

    return (
        <>
            {loading ? <Loader /> : <div> <div>
                <div className='feedback-header'>Give Feedback</div>
                {success && <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className='feedback-success-header'>Your feedback has been submitted!</div>
                </div>}
                <div className='feedback-container'>
                    <div>
                        {!success && <div className='feedback-container-title'>Rate your experience!😃</div>}

                        <div className='feedback-section'>
                            <div>
                                <div className='feedback'>
                                    <FeedbackRating label='Overall' rating={overall} onRatingChange={setOverall} />
                                </div>
                                <div className='feedback'>
                                    <FeedbackRating label='Food' rating={food} onRatingChange={setFood} />
                                </div>
                                <div className='feedback'>
                                    <FeedbackRating label='Drinks' rating={drinks} onRatingChange={setDrinks} />
                                </div>
                            </div>
                            <div>
                                <div className='feedback'>
                                    <FeedbackRating label='Service' rating={services} onRatingChange={setServices} />
                                </div>
                                <div className='feedback'>
                                    <FeedbackRating label='Value' rating={value} onRatingChange={setValue} />
                                </div>
                                <div className='feedback'>
                                    <FeedbackRating label='Wait Time' rating={waitTime} onRatingChange={setWaitTime} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={!success ? 'feedback-wait-staff-container' : ''}>
                        <div className='feedback-input-conatiner'>
                            <div className='feedback-label'>Wait Staff Name</div>
                            <input className='feedback-search-input'
                                placeholder='Write the name of your server'
                                value={waiterName} onChange={(e) => setWaiterName(e.target.value)}
                            />
                        </div>
                        <div className='feedback-input-conatiner'>
                            <div className='feedback-label'>What else is on your mind?</div>
                            <textarea className='feedback-textarea'
                                placeholder='Use this space to let the restaurant know more about your experience'
                                value={feedback} onChange={(e) => setFeedback(e.target.value)}
                            />
                        </div>
                    </div>
                    {success ? <div>
                        <div className='feedback-container-title'>Contact details</div>
                        <div>
                            <div className='feedback-label'>{name}</div>
                            <div className='feedback-label'>{email}</div>
                            <div className='feedback-label'>{phone}</div>
                        </div>
                    </div>
                        :
                        <div>
                            <div className='feedback-container-title'>May we contact you?☺️</div>
                            <div className='feedback-input-conatiner'>
                                <div className='feedback-label'>Name</div>
                                <input className='feedback-search-input' value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className='feedback-input-conatiner'>
                                <div className='feedback-label'>Email</div>
                                <input className='feedback-search-input' value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className='feedback-input-conatiner'>
                                <div className='feedback-label'>Phone Number</div>
                                <PhoneInput
                                    placeholder=""
                                    inputStyle={{
                                        borderRadius: '30px',
                                        border: '1px solid #c0cece',
                                    }}
                                    enableTerritories={true}
                                    country={"jm"}
                                    enableSearch={true}
                                    value={phone}
                                    onChange={(e) => setPhone(e)}
                                    autoFormat={false}

                                />
                            </div>
                        </div>}

                </div>
                {success ?
                    <div style={{ justifyContent: 'center', display: 'flex', marginBottom: '80px' }}>
                        <button className='feedback-submit-btn'>
                            <a href={scheduleReservationURL} style={{ color: 'white' }}>Schedule Again</a>
                        </button>
                    </div>
                    :
                    <div style={{ justifyContent: 'center', display: 'flex', marginBottom: '80px' }}>
                        <button className='feedback-submit-btn' onClick={handleSubmit}>Submit</button>
                    </div>}

            </div> <div>
                    <div className='feedback-footer'>
                        <Footer />
                    </div>
                </div></div>}

        </>
    )
}

export default Feedback

import React, { FC } from 'react'
import { images, initialMonths, weekDays } from '../../../commonConfig'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Modal } from 'antd';
import './feedback.scss'
import moment from 'moment'

type FeedbackModalProps = {
    open: any,
    close: any,
    feedback: any,
    highestVotedRating: any,
    totalVotesForHighestRated: any,
    ratingsCount: any,
}

const FeedbackModal: FC<FeedbackModalProps> = (props) => {
    const { close, open, feedback, highestVotedRating, totalVotesForHighestRated, ratingsCount } = props;

    const progressBarData = [
        { label: 5, now: 100 },
        { label: 4, now: 80 },
        { label: 3, now: 60 },
        { label: 2, now: 40 },
        { label: 1, now: 20 },
    ]
    return (
        <Modal
            className="feedback-modal"
            open={open}
            onCancel={close}
        >
            <div className="feedback-main_container">
                <div className="feedback-header-modal">
                    <div className="feedback-header-container">
                        <div className="feedback-rating">{highestVotedRating}</div>
                        <div style={{ marginTop: "-8px" }}> {Array.from({ length: highestVotedRating }, (_, index) => (
                            <img key={index} src={images.IMG_STAR_DARK_FILLED} className="feedback-star" />
                        ))}</div>
                        <div className="feedback-total">( {totalVotesForHighestRated} )</div>
                    </div>
                    <div style={{ width: "100%" }} className='rating-lines-container'>
                        {progressBarData?.map((data) => {
                            const count = ratingsCount && ratingsCount[data.label];
                            return (
                                count !== undefined &&
                                <div className='rating-lines' key={data.label}>
                                    <ProgressBar now={data.now} />
                                    <div className='total-votes-rating-number'>
                                        {count}
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                </div>
                <div className="feedback-body-main-container">
                    {feedback && feedback.length ?
                        feedback
                            .sort((a: any, b: any) => moment(b.date, 'MM/DD/YYYY').diff(moment(a.date, 'MM/DD/YYYY')))
                            .map((item: any) => {
                                const date = new Date(item.date);
                                const resDate = date.getDate()
                                const resYear = date.getFullYear()
                                const monthName = initialMonths[date.getMonth()]
                                const dayName = weekDays[date.getDay()] // Thu
                                const formatted = `${resDate} ${monthName} ${resYear}`
                                return (<div className="feedback-body-container">
                                    <div className="feedback-name">{item.name} </div>
                                    <div className="feedback-date-star">
                                        {Array.from({ length: item.ratings.overall }, (_, index) => (
                                            <img key={index} src={images.IMG_STAR_FILLED} className="feedback-rating-mobile" />
                                        ))}
                                        <div className="feedback-date">{formatted}</div></div>
                                    <div className="feedback-comment">{item.feedback}</div>
                                </div>
                                )
                            }) : "No Feedback"}
                </div>
            </div>

        </Modal>
    )
}

export default FeedbackModal

import React, { FC, useEffect, useState } from "react";
import Select from 'react-select';

import './select-input-time.scss'

interface option {
  value: string;
  label: string;
}

type SelectInputProps = {
  selected: any;
  placeholder: string;
  type: 'time';
  formik: any;
  manualOptions: option[];
  onChange?: Function;
  index?: number;
  id: any,
  disabled?: boolean;
}

const SelectInputTime: FC<SelectInputProps> = props => {

  const { selected, placeholder, type, formik, manualOptions, id } = props;

  const [options, setOptions] = useState<Array<option>>([]);
  const [selecteOption, setSelecteOption] = useState<option | option[]>();

  useEffect(() => {
    switch (type) {
      case 'time':
        setSelecteOption(manualOptions.find(op => op.value === selected));
        break;
    }

  }, [selected, manualOptions]);

  useEffect(() => {
    switch (type) {
      case 'time':
        setOptions(manualOptions)
        break;
    }
  }, [manualOptions]);


  const onChange = (value: any) => {
    setSelecteOption(value)
    formik.setFieldValue(type, value.value, false);
    formik.setFieldError(type, undefined)
  }
  return (
    <div className="time-input ">
      {
        <Select
          isDisabled={props.disabled}
          id={id}
          value={selecteOption}
          placeholder={placeholder}
          options={options}
          onChange={(value: any) => onChange(value)}
          styles={{
            control: (baseStyles: any, state: any) => ({
              ...baseStyles,
              borderRadius: state.menuIsOpen ? '18px 18px 0 0' : '33px',
              paddingLeft: props.disabled ? '25px' : '0px',
              boxShadow: 'none',
              background: state.isFocused ? 'linear-gradient(white, white) padding-box,linear-gradient(95.39deg, #B2D236 9.4%, #2BB24B 51.68%, #01A8AC 96.92%) border-box' : props.disabled ? '#c0cece6e' : '',
              border: state.isFocused ? '1px solid #c0cece' : formik.errors.time !== undefined ? '1px solid #ef4444' : '',
              borderBottom: state.menuIsOpen ? 'none' : '',
              "&:hover": {
                background: 'linear-gradient(white, white) padding-box,linear-gradient(95.39deg, #B2D236 9.4%, #2BB24B 51.68%, #01A8AC 96.92%) border-box',
                border: '1px solid transparent'
              },
              minHeight: '36px',
              "::-webkit-scrollbar": {
                display: "none"
              },
              "::-webkit-scrollbar-track": {
                display: "none"
              },
              "::-webkit-scrollbar-thumb": {
                display: "none"
              },
              "::-webkit-scrollbar-thumb:hover": {
                display: "none"
              }
            }),
            multiValue: (baseStyles: any, state: any) => ({
              ...baseStyles,
              backgroundColor: 'transparent',
              borderRadius: '12px',
              border: '1px solid #026669',
              marginTop: '2.5px',
              marginLeft: '5px',
              borderColor: '#01a8ac',
              "::-webkit-scrollbar": {
                display: "none"
              },
              "::-webkit-scrollbar-track": {
                display: "none"
              },
              "::-webkit-scrollbar-thumb": {
                display: "none"
              },
              "::-webkit-scrollbar-thumb:hover": {
                display: "none"
              }
            })
          }} />
      }
    </div>
  )
}
export default SelectInputTime;


import React, { FC, useState, useEffect } from "react";
import { IoChevronDownSharp } from "react-icons/io5";
import { IoChevronUpSharp } from "react-icons/io5";
import "./Category.scss";
import { db } from "../../../firebase/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import CategoryMenuItem from "../../molecules/Category-Menu-Item/Categorymenu";
import "react-multi-carousel/lib/styles.css";
import { images } from "../../../commonConfig";
import { taxProps, logoImageProps } from "../../../interfaces";
import { TrackGoogleAnalyticsEvent } from "../../../googleAnalytics/GoogleAnalytics";
var uniqueItemArray: any;
type Item = {
  item: any;
  onPress: any;
  opened: any;
  category: any;
  taxProps: taxProps;
  logoImageProps: logoImageProps;
  subCategoryData: any[];
  restaurantId: string;
  searchString: string;
  menuFeedbackData: any;
};

const Category: FC<Item> = ({
  item,
  onPress,
  opened,
  category,
  taxProps,
  logoImageProps,
  subCategoryData,
  restaurantId,
  searchString,
  menuFeedbackData
}) => {
  const navigate = useNavigate();

  const { localTax, serviceCharge } = taxProps;
  const { logoImage } = logoImageProps;

  const [resId, setResId] = useState<any>();
  const [resData, setResData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [menuItem, setMenuItem] = useState<any>([]);
  const [sponsorData, setSponsorData] = useState<any>([]);

  let URL = window.location.href;
  if (window.location.search) {
    URL = URL.split(window.location.search)[0];
  }

  const getSponsorData = async () => {
    const sponsorsRef = collection(db, `Restaurant/${resId}/Sponsors`);
    const sponsorQuery = query(
      sponsorsRef,
      where("sponser_hide_status", "==", false)
    );
    const sponsorsShot = await getDocs(sponsorQuery);
    const sponsorsList = sponsorsShot.docs.map((doc) => doc.data());
    setSponsorData(sponsorsList);
  };

  const getUrl = async () => {
    const temp: any = [];
    const ResCollectionRef = collection(db, "Restaurant");
    (await getDocs(query(ResCollectionRef, where("url", "==", URL)))).forEach(
      (doc) => {
        if (doc) {
          temp.push(doc.data());
          setResData({ ...doc.data(), id: doc.id });
          setResId(doc.id);
        }
      }
    );
  };

  const getDataMenu = async () => {
    setLoading(true);
    const temp: any = [];
    await db
      .collection("Restaurant")
      .doc(resId)
      .collection("Items")
      .where("item_status", "==", false)
      .orderBy("menuIndex")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          await temp.push(doc.data());
          await temp.sort((a: any, b: any) => a.menuIndex - b.menuIndex);
          setMenuItem(temp);
          setLoading(false);
        });
      });
  };
  useEffect(() => {
    getDataMenu();
    getSponsorData();
  }, [resId]);

  useEffect(() => {
    getUrl();
  }, [loading]);

  const ItemData = menuItem?.filter((obj: any) =>
    obj.category_tag?.includes(item.name)
  );

  const SubCatData = subCategoryData?.filter((obj: any) =>
    obj.category?.includes(item.name)
  );


  const getUniqueItemData = () => {
    var ItemDataCopy = [...ItemData];
    // if (ItemData.length && SubCatData.length) {
    //   ItemData.map((item: any, index: any) => {
    //     if (item?.subCategories?.length > 0) {
    //       var data = SubCatData.filter((sub: any) =>
    //         item.subCategories?.includes(sub.name)
    //       );
    //       if (data.length > 0) {
    //         ItemDataCopy.splice(index, 1, data[0]);
    //       }
    //     }
    //   });
    // }
    // var mergedData = [...ItemDataCopy, ...SubCatData];
    // var uniqueSet = new Set(mergedData);
    // uniqueItemArray = Array.from(uniqueSet);


    if (ItemData.length && SubCatData.length) {
      ItemDataCopy.forEach((item, index) => {
        if (item?.subCategories?.length > 0) {
          var data = SubCatData.find((sub: any) =>
            item.subCategories.includes(sub.name)
          );
          if (data) {
            ItemDataCopy[index] = data;
          }
        }
      });
    }
    var mergedData = [...ItemDataCopy, ...SubCatData]
    var mergedArray = mergedData.map((item) => {
      var matchingItem = menuFeedbackData.find((element: any) => element.id === item.id);
      return { ...item, ...matchingItem };
    });
    var uniqueSet = new Set(mergedArray.map(item => item.id));
    var uniqueItemArray = Array.from(uniqueSet).map(id => mergedArray.find(item => item.id === id));


    if (searchString !== "" && searchString?.length) {
      const filtered = uniqueItemArray.filter((item: any) => {
        return `${item.name?.toLowerCase()}`.includes(
          searchString?.toLowerCase()
        );
      });

      uniqueItemArray = filtered
    }
    var res = uniqueItemArray.length > 0 ? (
      <>
        {uniqueItemArray?.map((obj: any, index: any) => (
          <div
            key={index}
            className="subcategory-card"
            onClick={() => {
              navigate(`/${resData.uniqId}/details`, {
                state: {
                  obj,
                  item,
                  resData,
                  ItemData,
                  index,
                  subCategoryData,
                  SubCatData,
                  menuItem,
                  uniqueItemArray,
                },
              });
              TrackGoogleAnalyticsEvent(item.name, 'Category_Name', 'Category')
              TrackGoogleAnalyticsEvent(obj.name, 'Item_Name', 'Item')
            }
            }
          >
            {!loading ? (
              <CategoryMenuItem
                key={index}
                data={obj}
                resData={resData}
                logoImageProps={logoImageProps}
              />
            ) : (
              <Skeleton height="200px" width="100%" />
            )}
          </div>
        ))}
      </>
    ) : (
      <div className="no-found-section" >
        <img src={images.NO_FOUND} alt="icon" className="no-img" />
        <div className="no-tags-menu">
          Oops, We couldn't find a match!
        </div>
        <div className="no-tags-item">Try searching for something else</div>
      </div>
    )

    return res;
  };
  const currentTime = new Date();

  const timestampMilliseconds = item?.isNewUpto?.seconds * 1000 + item?.isNewUpto?.nanoseconds / 1e6;
  const providedDateTime = new Date(timestampMilliseconds);

  return (
    <>
      <div
        className={
          item.categoryImageUrl ? "main-category-opened" : "main-category"
        }
        onClick={onPress}
      >
        <div
          className={
            item.categoryImageUrl ? "image-section-opened" : "image-section"
          }
        >
          <div
            className={
              item.categoryImageUrl
                ? "category-name-section-opened"
                : "category-name-section"
            }
          >
            {providedDateTime > currentTime ? <div className="new_item">New</div> : ''}

            <div
              className={
                item.categoryImageUrl ? "category-name-opened" : "category-name"
              }
            >
              {item.name}
            </div>
            {(opened || searchString?.length > 0) ? (
              <IoChevronUpSharp className="opend-icon" size={55} />
            ) : (
              <IoChevronDownSharp className="opend-icon" size={55} />
            )}
          </div>
          {item.categoryImageUrl ? (
            <img
              src={item.categoryImageUrl}
              alt="icon"
              className="category-image"
            />
          ) : (
            <div className="category-image"></div>
          )}
        </div>
      </div>

      {(opened || searchString?.length > 0) && (
        <div>
          {item.description && uniqueItemArray?.length ? (
            <p className="main-subcategory-text">{item.description} </p>
          ) : ''}
          {(localTax || serviceCharge) && (
            <div className="main-subcategory-subtext">
              <span>
                FINAL PRICE WILL INCLUDE: {localTax && localTax + "% TAX "}
                {localTax && serviceCharge && "+ "}
                {serviceCharge && serviceCharge + "% SERVICE CHARGE"}
              </span>
            </div>
          )}
          <div className="main-subcategory">
            {!loading ? (
              <>
                {getUniqueItemData()}
              </>
            ) : (
              <Skeleton height="200px" width="100%" />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Category;

import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

import { GoDotFill } from 'react-icons/go'

import './confirmation-success.scss'
import { images } from '../../../commonConfig';
import { Webmenu } from '../../../commonConfig/base';
import Footer from '../../../components/molecules/Footer/Footer';

const ConfirmationSuccess = () => {
    const [restaurantData, setRestaurantData] = useState<any>();
    const [reservationData, setReservationData] = useState<any>();
    const [displayDate, setDisplayDate] = useState<any>()

    const location = useLocation();
    let data = location?.state;


    useEffect(() => {
        if (data !== undefined) {
            setReservationData(data?.reservationData);
            setRestaurantData(data?.restaurantData);
            setDisplayDate(data?.date)
        }
    }, [data]);

    return (
        <>
            <div>
                <div className='confirm-reschedule-container'>
                    <div>
                        <h2 className='confirm-reschedule-title'>Reservation Confirmed</h2>
                    </div>
                </div>

                <div className='confirm-reschedule-resmain-container'>
                    <div>
                        <div className='confirm-reschedule-get-ready'>Get ready to chow down!</div>
                        <div className='confirm-reschedule-reserv-text'>Your reservation has been sent.</div>
                        <div className='confirm-reschedule-celebration'>
                            <img alt='#' src={images.IMG_CELEBRATION} className='confirm-reschedule-celebration-image' /></div>

                    </div>
                    <div className='edit-confirm-details-schedule-main'>

                        <div className='edit-confirm-details-schedule' >
                            <div className='edit-details-container' style={{ width: "100%" }}>
                                <div>
                                    <h3 className='confirm-reschedule-title-confirm confirm-reschedule-contact-title' >Contact</h3>
                                    <div className='reserv-center' style={{ marginBottom: "60px" }}>
                                        <div>
                                            <div className='confirm-reschedule-content '>
                                                <p>{reservationData?.first_name} {reservationData?.last_name}</p>
                                                <p>{reservationData?.email}</p>
                                                <p> {reservationData?.phone} </p>
                                                {/* <p>Sophronia Mckenzie</p>
                                            <p>smckenzie@visueats.com</p>
                                            <p> (333) 123 - 4567 </p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h3 className='confirm-reschedule-contact-title' >Reservation Details</h3>
                                    <div className='reserv-center' style={{ marginBottom: "60px" }}>
                                        <div style={{ paddingTop: "30px" }} className='confirm-reschedule-content '>
                                            <h5 className='res-reserv-title-cancel'>{restaurantData?.name}</h5>
                                            <div className='reserv-content-cancel-reschedule' >
                                                <p style={{ marginRight: "5px" }}>{restaurantData?.type ? restaurantData.type : 'Restaurant'}</p>
                                                <GoDotFill size={8} />
                                                <p style={{ marginLeft: "5px" }}>{restaurantData?.city} {restaurantData?.state}</p>
                                            </div>

                                            <div className='reservation-details'>
                                                <div className='reserve-details'>
                                                    <div className='reserv-icon'>
                                                        <img alt='#' src={images.IMG_GUEST} className="product-icon-img" />
                                                    </div>
                                                    <div className='reservation-restaurant-details'>{reservationData?.members}</div>
                                                </div>
                                                <div className='reserve-details calendar-icon-cancel'>
                                                    <div className='reserv-icon'>
                                                        <img alt='#' src={images.IMG_RESERVE} className="product-icon-img" />
                                                    </div>
                                                    <div className='reservation-restaurant-details'> {displayDate} at {reservationData?.time}</div>
                                                </div>
                                            </div>
                                            {reservationData?.seating_reserv && reservationData?.seating_reserv.length ? <div className='reservation-restaurant-details' style={{ marginTop: "10px" }}>
                                                <img alt='' src={images.IMG_SEATING} className="product-icon-img" style={{ marginRight: "10px" }} />
                                                {reservationData?.seating_reserv}
                                            </div> : null}
                                            {reservationData?.deposit && reservationData.deposit !== '0' ? < div className='reservation-restaurant-details' style={{ marginTop: "10px" }}>
                                                <img alt='' src={images.IMG_DEPOSIT} className="product-icon-img" style={{ marginRight: "10px", width: "20px", height: "20px" }} />
                                                ${reservationData?.deposit} USD
                                            </div> : null}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div style={{ width: "100%" }} className='special-confirm'>
                                <div>
                                    <h3 className='reserv-details-title-special confirm-reschedule-contact-title' >Special Occasions</h3>
                                    <div className='confirm-reschedule-content '>
                                        {reservationData?.occasion && reservationData?.occasion.length ?
                                            <div className='reserve-table-selected-list-conatiner-confirm'>
                                                {reservationData?.occasion.map((item: any, index: number) => {
                                                    return <div style={{ position: 'relative' }}>
                                                        <div className='reserve-table-selected-list'>
                                                            <div className='reserve-table-selected-label'>{item}</div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div> : 'N/A'}
                                    </div>
                                </div>
                                <div><h3 className='reserv-details-title-special confirm-reschedule-contact-title' >Dietary Restrictions</h3>
                                    <div className='confirm-reschedule-content '>
                                        {reservationData?.dietary && reservationData?.dietary.length ?
                                            <div className='reserve-table-selected-list-conatiner-confirm'>
                                                {reservationData?.dietary.map((item: any, index: number) => {
                                                    return <div style={{ position: 'relative' }}>
                                                        <div className='reserve-table-selected-list'>
                                                            <div className='reserve-table-selected-label'>{item}</div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div> : 'N/A'}
                                    </div>
                                </div>
                                {/* <div><h3 className='reserv-details-title-special confirm-reschedule-contact-title' >Pre-Order</h3>  <div className='confirm-reschedule-content '></div></div> */}
                                <div>
                                    <h3 className='reserv-details-title-special confirm-reschedule-contact-title' >Special Requests</h3>
                                    <div className='confirm-reschedule-content '>{reservationData?.specialRequest?.length ? reservationData?.specialRequest : 'N/A'}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                   
                    <div className='browse-res-reschedule' >
                        <a href={Webmenu.API} className="btns save-changes browse-res-btn-schedule" >Browse Restaurants</a>
                    </div>
                   
                </div>
            </div>

            <Footer />
        </>
    )
}

export default ConfirmationSuccess
import React, { FC } from 'react';
import { images } from '../../../../commonConfig';
import './feedback-rating.scss'

type FeedbackRatingProps = {
    label: any,
    rating: any,
    onRatingChange: any
}

const FeedbackRating: FC<FeedbackRatingProps> = ({ label, rating, onRatingChange }) => {
    return (
        <div className='feedback'>
            <div className='feedback-label'>{label}</div>
            <div className='feedback-rating-container'>
                {[1, 2, 3, 4, 5].map((value) => (
                    <div key={value} onClick={() => onRatingChange(value)}>
                        <img
                            src={rating >= value ? images.IMG_STAR_FILLED : images.IMG_STAR}
                            alt='rating'
                            className='feedback-rating-img'
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FeedbackRating;
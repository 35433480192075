import React, { FC, useEffect, useState } from 'react'

import { GoDotFill } from 'react-icons/go';

import { DIETARY, EmailBase, OCCASIONS, images } from '../../../commonConfig';
import CheckboxModal from '../../../components/atoms/checkbox-modal/checkbox-modal';
import './reserve-table.scss'

type ReserveTableProps = {
    formik: any,
    restaurantData: any,
    displayResDate: any,
    handleFormSubmit: any,
}

const ReserveTable: FC<ReserveTableProps> = ({ formik, restaurantData, displayResDate, handleFormSubmit }) => {
    const [termsAndCond, setTermsAndCond] = useState<boolean>(false);
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [preOrderModal, setPreOrderModal] = useState<boolean>(false)
    const [dietaryModal, setDietaryModal] = useState<boolean>(false)
    const [occasionModal, setOccasionModal] = useState<boolean>(false);
    const [dietary, setDietary] = useState<any>([]);
    const [occasion, setOccasion] = useState<any>([]);

    const handleSubmit = async () => {
        if (rememberMe && termsAndCond) {
            handleFormSubmit()
        }
    }

    useEffect(() => {
        if (formik.values.occasion && formik.values.occasion.length) {
            setOccasion(formik.values.occasion)
        }
        if (formik.values.dietary && formik.values.dietary.length) {
            setDietary(formik.values.dietary)
        }
    }, [formik.values]);

    const handleRemoveSelected = (index: number, field: string) => {
        if (field === 'dietary') {
            var temp: any = [...dietary]
            temp.splice(index, 1)
            setDietary(temp)
        } else if (field === 'occasion') {
            var temp2: any = [...occasion]
            temp2.splice(index, 1)
            setOccasion(temp2)
        }
    }

    useEffect(() => {
        formik.setFieldValue('occasion', occasion, false)
        formik.setFieldValue('dietary', dietary, false)
    }, [dietary, occasion])

    return (
        <div>
            <div className="schedule-reserve-table-header">Reserve a Table</div>
            <div className='reserve-table-container'>
                <div className='reserve-table-input-container' onClick={() => setOccasionModal(true)} style={{ marginTop: "38px" }}>
                    <img className='reserve-table-add-icon' src={images.IMG_RES_ADD} alt='#' />
                    <div className='reserve-table-label'>Special Occasion</div>
                </div>
                {formik.values.occasion && formik.values.occasion.length ?
                    <div className='reserve-table-selected-list-conatiner'>
                        {formik.values.occasion.map((item: any, index: number) => {
                            return <div style={{ position: 'relative' }}>
                                <div className='reserve-table-selected-list'>
                                    <div className='reserve-table-selected-label'>{item}</div>
                                </div>
                                <div className='reserve-table-remove-item' onClick={() => { handleRemoveSelected(index, 'occasion') }}>
                                    <img className='reserve-table-remove-item-img' alt='red-cross' src={images.IMG_RED_CROSS} />
                                </div>
                            </div>
                        })}
                    </div> : null}

                <div className='reserve-table-input-container' onClick={() => setDietaryModal(true)}>
                    <img className='reserve-table-add-icon' src={images.IMG_RES_ADD} alt='#' />
                    <div className='reserve-table-label'>Dietary Restrictions</div>
                </div>
                {formik.values.dietary && formik.values.dietary.length ?
                    <div className='reserve-table-selected-list-conatiner'>
                        {formik.values.dietary.map((item: any, index: number) => {
                            return <div style={{ position: 'relative' }}>
                                <div className='reserve-table-selected-list'>
                                    <div className='reserve-table-selected-label'>{item}</div>
                                </div>
                                <div className='reserve-table-remove-item' onClick={() => { handleRemoveSelected(index, 'dietary') }}>
                                    <img className='reserve-table-remove-item-img' alt='red-cross' src={images.IMG_RED_CROSS} />
                                </div>
                            </div>
                        })}
                    </div> : null}

                {/* <div className='reserve-table-input-container' onClick={() => setPreOrderModal(true)}>
                    <img className='reserve-table-add-icon' src={images.IMG_RES_ADD} alt='#' />
                    <div className='reserve-table-label'>Pre-Order</div>
                </div> */}

                <div className='reserve-table-input-field-container'>
                    <div className='reserve-table-label'>Special Requests</div>
                    <textarea className='reserve-table-textarea' value={formik.values.specialRequest}
                        onChange={(e) => formik.setFieldValue('specialRequest', e.target.value, false)}
                        placeholder='Add any details you would like the restaurant to know before your reservation'
                    />
                </div>

                <div className='reserve-table-card-container'>
                    <div className='reserve-table-card-header'>Reservation Details</div>
                    <div className='reserve-table-res-container'>
                        <div className='reserve-table-res-name'>{restaurantData.name}</div>
                        <div style={{ display: 'flex', margin: '10px 0px' }}>
                            <div className='reserve-table-res-label'>{restaurantData.type}</div>
                            <div style={{ margin: '-2px 5px 0px 5px' }}><GoDotFill size={7} color='#546B6B' /></div>
                            <div className='reserve-table-res-label'>{restaurantData.city}</div>
                        </div>

                        <div className='reservation-details'>
                            <div className='reserve-details'>
                                <div className='reserv-icon'>
                                    <img alt='#' src={images.IMG_GUEST} className="product-icon-img" />
                                </div>
                                <div className='reserve-table-res-label'>{formik.values?.members}</div>
                            </div>
                            <div className='reserve-details calendar-icon-cancel'>
                                <div className='reserv-icon'>
                                    <img alt='#' src={images.IMG_RESERVE} className="product-icon-img" />
                                </div>
                                <div className='reserve-table-res-label'> {displayResDate} at {formik.values?.time}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='reserve-input-mainContainer'>
                    <div className='reserve-table-input-container' style={{ margin: '10px 0px' }}>
                        <div className="checkbox-input"
                        // style={{ width: '50px' }}
                        >
                            <label className="container">
                                <input type="checkbox" onClick={() => setRememberMe(!rememberMe)} />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className='reserve-table-checkbox-label'>Yes, I am 18 years of age or older*</div>
                    </div>
                    {!rememberMe && <div className='reserve-table-error-text'>*It is required to be 18 years of age or older to make a reservation </div>}
                    <div className='reserve-table-input-container' style={{ margin: '10px 0px' }}>
                        <div className="checkbox-input"
                        // style={{ width: '50px' }}
                        >
                            <label className="container">
                                <input type="checkbox" onClick={() => setTermsAndCond(!termsAndCond)} />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className='reserve-table-checkbox-label'>I agree to the{' '}
                            <a target='/_blank' rel='noreferrer' href={`${EmailBase.API}/terms-conditions?restId=${restaurantData?.id}`} className='terms_condition'>Terms and Conditions*</a>
                        </div>
                    </div>
                    {!termsAndCond && <div className='reserve-table-error-text'>*It is required to agree with the terms and conditions to make a reservation.</div>}
                </div>
                <div className="schedule-continue-container" style={{ marginTop: '30px' }}>
                    <div className={rememberMe && termsAndCond ? "schedule-continue" : "schedule-continue-disabled"} onClick={handleSubmit}>
                        Submit
                    </div>
                </div>
            </div>
            <CheckboxModal
                open={preOrderModal}
                close={() => setPreOrderModal(false)}
                mode='downloadApp'
            />

            <CheckboxModal
                open={dietaryModal}
                close={() => setDietaryModal(false)}
                header='Dietary Restrictions'
                list={DIETARY}
                selectedCheckbox={dietary}
                setSelectedCheckbox={setDietary}
            />

            <CheckboxModal
                open={occasionModal}
                close={() => setOccasionModal(false)}
                header='Special Occasion'
                list={OCCASIONS}
                selectedCheckbox={occasion}
                setSelectedCheckbox={setOccasion}
            />

        </div>
    )
}

export default ReserveTable
import { IFirebaseConfig } from "./interface";

const firebase_config: IFirebaseConfig = {
    development: {
        apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_DEV_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_DEV_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_DEV_FIREBASE_MEASUREMENT_ID
    },
    production: {
        apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_PROD_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROD_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_PROD_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_PROD_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_PROD_FIREBASE_MEASUREMENT_ID
    },
    test: {
        apiKey: process.env.REACT_APP_TEST_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_TEST_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_TEST_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_TEST_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_TEST_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_TEST_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_TEST_FIREBASE_MEASUREMENT_ID
    }
};

let config = {};

switch (process.env.REACT_APP_ENV) {
    case "development":
        config = firebase_config.development;
        break;
    case "production":
        config = firebase_config.production;
        break;
    case "test":
        config = firebase_config.test;
        break;
    default:
        config = firebase_config.development;
        break; 
}

export default config;
import React, { FC, useState, useEffect } from "react";
import "./CategoryTab.scss";
import { db } from "../../../firebase/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { images } from "../../../commonConfig/index";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { GoDotFill } from "react-icons/go";
import { AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import Skeleton from "react-loading-skeleton";
import { TrackGoogleAnalyticsEvent } from "../../../googleAnalytics/GoogleAnalytics";

var uniqueItemArray: any;

type Item = {
  item: any;
  category: any;
  selectedTag: any;
  categoryIndex: any;
  taxProps: any;
  tags: string[];
  logoImageProps: any;
  subCategoryData: any;
  restaurantId: string;
  searchString: string;
  menuFeedbackData: any

  // setUniqueItemArray:React.Dispatch<React.SetStateAction<any[]>>
};

const CategoryTab: FC<Item> = ({
  item,
  category,
  selectedTag,
  categoryIndex,
  taxProps,
  tags,
  logoImageProps,
  subCategoryData,
  restaurantId,
  searchString,
  menuFeedbackData
  // setUniqueItemArray
}) => {
  const navigate = useNavigate();
  const { localTax, serviceCharge } = taxProps;
  const { logoImage } = logoImageProps;

  const [resId, setResId] = useState<any>();
  const [resData, setResData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [mLoading, setMLoading] = useState(true);
  const [displayItems, setDisplayItems] = useState<any>();

  let URL = window.location.href;
  if (window.location.search) {
    URL = URL.split(window.location.search)[0];
  }

  const getUrl = async () => {
    const temp: any = [];
    const ResCollectionRef = collection(db, "Restaurant");

    (await getDocs(query(ResCollectionRef, where("url", "==", URL)))).forEach(
      (doc) => {
        if (doc) {
          temp.push(doc.data());
          setResData(doc.data());
          setResId(doc.id);
        }
      }
    );
  };
  const [menuItem, setMenuItem] = useState<any[]>([]);


  const getDataMenu = async () => {
    setLoading(true);
    const temp: any = [];
    try {
      await db
        .collection("Restaurant")
        .doc(resId)
        .collection("Items")
        .where("item_status", "==", false)
        .orderBy("menuIndex")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            await temp.push(doc.data());
            await temp.sort((a: any, b: any) => a.menuIndex - b.menuIndex);
            setMenuItem(temp);
            setLoading(false);
          });
        });
      setTimeout(() => {
        setMLoading(false);
      }, 3000);
    } catch (error) {
      // console.log(error);
      setLoading(false);
      setMLoading(false);
    }
  };

  useEffect(() => {
    getDataMenu();
  }, [resId]);

  useEffect(() => {
    getUrl();
  }, [loading]);

  const ItemData = menuItem?.filter((obj: any) =>
    obj.category_tag?.includes(item.name)
  );

  const subData = subCategoryData?.filter((obj: any) =>
    obj.category?.includes(item.name)
  );

  const currentTime = new Date();

  const getUniqueItemData = () => {
    var ItemDataCopy = [...ItemData];
    if (ItemData.length && subData.length) {
        ItemDataCopy.forEach((item, index) => {
            if (item?.subCategories?.length > 0) {
                var data = subData.find((sub:any) =>
                    item.subCategories.includes(sub.name)
                );
                if (data) {
                    ItemDataCopy[index] = data;
                }
            }
        });
    }
    var mergedData = ItemDataCopy.concat(subData);
    var mergedArray = mergedData.map((item) => {
        var matchingItem = menuFeedbackData.find((element:any) => element.id === item.id);
        return { ...item, ...matchingItem };
    });
    var uniqueSet = new Set(mergedArray.map(item => item.id));
    var uniqueItemArray = Array.from(uniqueSet).map(id => mergedArray.find(item => item.id === id));
    // var uniqueSet = new Set(mergedArray);
    // uniqueItemArray = Array.from(uniqueSet);
    // uniqueItemArray.pop()
    if (searchString !== "" && searchString?.length) {
      const filtered = uniqueItemArray.filter((item: any) => {
        return `${item.name?.toLowerCase()}`.includes(
          searchString?.toLowerCase()
        );
      });
      uniqueItemArray = filtered;
    }

    var res = uniqueItemArray?.length ? (
      <Carousel
        responsive={responsive}
        showDots={uniqueItemArray.length > 4 ? true : false}
        customDot={<CustomDot />}
        keyBoardControl
        arrows={false}
        customButtonGroup={<ButtonGroup />}
      >
        {uniqueItemArray?.map((ele: any, index: any) => {

          const timestampMilliseconds = ele?.isNewUpto?.seconds * 1000 + ele?.isNewUpto?.nanoseconds / 1e6;
          const providedDateTime = new Date(timestampMilliseconds);

          return (
            <div
              key={index}
              className="tag-category-data-1"
              onClick={() => {
                navigate(`/${resData.uniqId}/details`, {
                  state: {
                    ele,
                    item,
                    resData,
                    ItemData,
                    index,
                    category,
                    menuItem,
                    URL,
                    selectedTag,
                    resId,
                    categoryIndex,
                    tags,
                    subData,
                  },
                });
                TrackGoogleAnalyticsEvent(item.name, 'Category_Name', 'Category')
                TrackGoogleAnalyticsEvent(ele.name, 'Item_Name', 'Item')
              }
              }
            >
              <div className="tag-category-data">
                <div className="soldOut-image-container">
                  {ele?.coverImageURL && (
                    <img
                      className="data-img"
                      src={ele.coverImageURL}
                      alt="icon"
                      width="200"
                      height="150"
                    />
                  )}
                  {ele?.itemCoverImageUrl && (
                    <img
                      className="data-img"
                      src={ele.itemCoverImageUrl}
                      alt="icon"
                      width="200"
                      height="150"
                    />
                  )}
                  {ele?.itemCoverImageUrl === "" || ele?.coverImageURL == "" ? (
                    <img
                      className="data-img"
                      src={images.IMG_DEFAULT_LOGO}
                      alt="icon"
                      width="200"
                      height="150"
                    />
                  ) : (
                    ""
                  )}
                  {providedDateTime > currentTime ? <div className="new_item">New</div> : ''}
                  {ele?.sold_out && (
                    <div className="sold-out-text">
                      {ele?.sold_out && "SOLD OUT"}
                    </div>
                  )}
                  {ele?.category && <div className="view-all">View All</div>}
                </div>
                <>
                  <div className="item-title">{ele.name}</div>
                  <div className="item-price">
                    {ele.price && `$${ele.price} ${resData.currency}`}
                  </div>
                  {ele?.rate ? <div className='feedback-text-homepage'>
                    <img src={images.IMG_STAR_DARK_FILLED} className="feedback-star-home" /><div>{ele?.rate}</div>
                  </div> : ""}
                </>
              </div>
            </div>
          );
        })}
      </Carousel>
    ) : (
      <>
        {searchString.length && searchString !== "" ? (
          <div className="no-found-section">
            <img src={images.NO_FOUND} alt="icon" className="no-img" />
            <div className="no-tags-item">
              Oops! No match found in the menu item
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
    return res;
  };


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 10000, min: 1601 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1600, min: 1200 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1200, min: 750 },
      items: 2,
    },
    smalltablet: {
      breakpoint: { max: 750, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [ITEM_TO_SHOW, SET_ITEM_TO_SHOW] = useState(4);

  const CustomDot = ({ onMove, index, onClick, active }: any) => {
    switch (index) {
      case 4:
        SET_ITEM_TO_SHOW(2);
        break;
      case 3:
        SET_ITEM_TO_SHOW(3);
        break;
      case 2:
        SET_ITEM_TO_SHOW(4);
        break;
      default:
        SET_ITEM_TO_SHOW(1);
        break;
    }

    return (
      <li
        className={active ? "active" : "inactive"}
        onClick={() => onClick()}
        style={active ? { color: "#20a8ac" } : { color: "#e2e9e9" }}
      >
        {uniqueItemArray?.length !==0 && <GoDotFill size={11} />}
      </li>
    );
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
    const {
      carouselState: { currentSlide, totalItems },
    } = rest;

    return (
      <div
        className="carousel-button-group"
        style={{
          color: "#20a8ac",
          position: "absolute",
          zIndex: "15",
          top: "0px",
          justifyContent: "flex-end",
          width: "100%",
          display: "flex",
          gap: "200px",
          cursor: "pointer",
        }}
      >
        <AiOutlineLeft
          className={currentSlide === 0 ? "disable" : ""}
          style={currentSlide === 0 ? { color: "#f4f6f6" } : {}}
          size={20}
          onClick={() => previous()}
        />
        <AiOutlineRight
          style={
            currentSlide > totalItems / ITEM_TO_SHOW ||
              totalItems <= ITEM_TO_SHOW ||
              totalItems == 4
              ? { color: "#f4f6f6" }
              : {}
          }
          onClick={() => next()}
          size={20}
        />
      </div>
    );
  };

  return (
    <div>
      {!loading ? (
        <>
          {mLoading && <Skeleton height="200px" width="100%" />}
          <div style={{ width: "100%" }}>
            {uniqueItemArray && uniqueItemArray.length ? (
              <>
                <div className="tag-category-title">{item.name}</div>

                {item.description && (
                  <p className="main-subcategory-text-web">
                    {item.description}{" "}
                  </p>
                )}
                {(localTax || serviceCharge) && (
                  <div className="main-subcategory-subtext-web">
                    <span>
                      FINAL PRICE WILL INCLUDE:{" "}
                      {localTax && localTax + "% TAX "}
                      {localTax && serviceCharge && "+ "}
                      {serviceCharge && serviceCharge + "% SERVICE CHARGE"}
                    </span>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="tag-category-title">{item.name}</div>

                {item.description && (
                  <p className="main-subcategory-text-web">
                    {item.description}{" "}
                  </p>
                )}
                {(localTax || serviceCharge) && (
                  <div className="main-subcategory-subtext-web">
                    <span>
                      FINAL PRICE WILL INCLUDE:{" "}
                      {localTax && localTax + "% TAX "}
                      {localTax && serviceCharge && "+ "}
                      {serviceCharge && serviceCharge + "% SERVICE CHARGE"}
                    </span>
                  </div>
                )}
                {/* {
                  searchString.length === 0 && searchString === '' ?
                    <div className="no-found-section">
                      <img src={images.NO_FOUND} alt="icon" className="no-img" />
                      <div className="no-tags-item">
                        Oops! No Menu Item Found!
                      </div>
                    </div> : ''
                } */}
              </>
              // <></>
            )}
            {getUniqueItemData()}
          </div>
        </>
      ) : (
        <Skeleton height="200px" width="100%" />
      )}
    </div>
  );
};

export default CategoryTab;

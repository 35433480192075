import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import MainHomepage from "./pages/MainHomepage/MainHomepage";
import DetailsPage from "./pages/Detailspage/Detailspage";
import DetailsPageTab from "./pages/DetailspageTab/DetailspageTab";
import Homepage from "./pages/Homepage/Homepage";

import "antd/dist/antd.min.css";
import "./App.css";

import ManageHomePageTab from "./pages/ManageHomepageTab/ManageHomepageTab";
import InitializeGoogleAnalytics from "./googleAnalytics/GoogleAnalytics";
import ScheduleReservation from "./pages/Reservation/schedule-reservation/schedule-reservation";
import ConfirmationSuccess from "./pages/Reservation/confirmation-success/confirmation-success";
import Feedback from "./pages/Reservation/feedback/feedback";
import ErrorPage from "./components/atoms/errorPage/errorpage";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

function App() {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    InitializeGoogleAnalytics();
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var component: any;

  const renderRoutes = () => {
    if (windowSize.innerWidth > 768) {
      component = (
        <Routes>
          <Route path="/" element={<MainHomepage />} />
          <Route path="/:uniqId" element={<Homepage />} />
          <Route path="/:uniqId/schedule-reservation" element={<ScheduleReservation />} />
          <Route path="/:uniqId/reservation-reschedule-confirmed" element={<ConfirmationSuccess />} />
          <Route path="/:uniqId/reservation-feedback" element={<Feedback />} />
          {window.innerWidth <= 500 && (
            <Route path="/:uniqId/details" element={<DetailsPage />} />
          )}
          {window.innerWidth >= 501 && (
            <Route path="/:uniqId/details" element={<DetailsPageTab />} />
          )}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      );
    } else if (windowSize.innerWidth <= 768) {
      component = (
        <Routes>
          <Route path="/" element={<ManageHomePageTab />} />
          <Route path="/:uniqId" element={<Homepage />} />
          <Route path="/:uniqId/schedule-reservation" element={<ScheduleReservation />} />
          <Route path="/:uniqId/reservation-reschedule-confirmed" element={<ConfirmationSuccess />} />
          <Route path="/:uniqId/reservation-feedback" element={<Feedback />} />
          {window.innerWidth <= 500 && (
            <Route path="/:uniqId/details" element={<DetailsPage />} />
          )}
          {window.innerWidth >= 501 && (
            <Route path="/:uniqId/details" element={<DetailsPageTab />} />
          )}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      );
    }
    return component;
  };

  return <div className="App">{renderRoutes()}</div>;
}

export default App;

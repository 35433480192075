import React, { FC, useEffect, useState } from "react";

import { db } from "../../../firebase/firebase";
import MenuItemSection from "./Menu-item-child";

import "./Menu-item.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "../../loader/loader";
import { collection, getDocs, query, where } from "firebase/firestore";
import { images } from "../../../commonConfig";
import { logoImageProps } from "../../../interfaces";
import { useNavigate } from "react-router-dom";

type Item = {
  data: any;
  index: any;
  searchString: string;
};

const MenuItem: FC<Item> = ({ data, index, searchString }) => {
  const [bannerUrl, setBannerUrl] = useState<string>("");
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [itemData, setItemData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [sponsorData, setSponsorData] = useState<any>();
  const [logoImage, setLogoImage] = useState<string | undefined>("");
  const [displayData, setDisplayData] = useState([]);
  const [menuItemRatings, setMenuItemRatings] = useState<any[]>([])

  useEffect(() => {
    if (data?.itemCoverImageUrl?.includes(".png")) {
      setBannerUrl(data?.itemCoverImageUrl?.replace(".png", "_800x800.png"));
    } else if (data?.itemCoverImageUrl?.includes(".jpeg")) {
      setBannerUrl(data?.itemCoverImageUrl?.replace(".jpeg", "_800x800.jpeg"));
    } else if (data?.itemCoverImageUrl?.includes(".jpg")) {
      setBannerUrl(data?.itemCoverImageUrl?.replace(".jpg", "_800x800.jpg"));
    }
  }, [data]);

  useEffect(() => {
    if (data?.itemSponsorBannerUrl?.includes(".png")) {
      setLogoUrl(data?.itemSponsorBannerUrl?.replace(".png", "_800x800.png"));
    } else if (data?.itemSponsorBannerUrl?.includes(".jpeg")) {
      setLogoUrl(data?.itemSponsorBannerUrl?.replace(".jpeg", "_800x800.jpeg"));
    } else if (data?.itemSponsorBannerUrl?.includes(".jpg")) {
      setLogoUrl(data?.itemSponsorBannerUrl?.replace(".jpg", "_800x800.jpg"));
    }
  }, [data]);

  const getMenuFeedbackData = async () => {
    var menufeedbackRating: any = []
    var feedbackData: any
    await db
      .collection("Feedback")
      .where("restaurantId", "==", data.resData.id)
      .get()
      .then(async (snapshot) => {
        snapshot.docs.map((doc) => {
          feedbackData = doc.data()?.menuItemRatings
          if (feedbackData && feedbackData.length) {
            menufeedbackRating = [...menufeedbackRating, ...feedbackData]
          }
        })
        setMenuItemRatings(menufeedbackRating)
      }).catch((err) => console.log(err));
    setLoading(false);
  }

  const getAllItems = async () => {
    const items: any[] = [];
    await db
      .collection("Restaurant")
      .doc(data.resData.id)
      .collection("Items")
      .where("item_status", "==", false)
      .orderBy("menuIndex")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc: any) => {
          items.push(doc.data());
        });
      });
    setItemData(items);
    setLoading(false);
  };

  useEffect(() => {
    if (!loading)
      window.scrollTo({ behavior: "smooth", top: 655 * index + 75 });
  }, [loading]);

  useEffect(() => {
    getAllItems();
    getMenuFeedbackData()
  }, []);
  const getSponsorData = async () => {
    const sponsorsRef = collection(
      db,
      `Restaurant/${data?.resData.id}/Sponsors`
    );
    const sponsorQuery = query(
      sponsorsRef,
      where("sponser_hide_status", "==", false)
    );
    const sponsorsShot = await getDocs(sponsorQuery);
    const sponsorsList = sponsorsShot.docs.map((doc) => doc.data());
    setSponsorData(sponsorsList);
  };
  useEffect(() => {
    getSponsorData();
  }, [data]);

  const logoImageProps: logoImageProps = {
    logoImage,
  };
  useEffect(() => {
    if (searchString.length && searchString !== "") {
      const filtered = data?.uniqueItemArray?.filter((item: any) => {
        return `${item.name?.toLowerCase()}`.includes(
          searchString?.toLowerCase()
        );
      });
      setDisplayData(filtered);
    } else {
      if(!data?.uniqueItemArray){
        setDisplayData(data?.menuItem)
      }
      else{
        setDisplayData(data.uniqueItemArray);
      }
    }
  }, [searchString]);

  const navigate = useNavigate();

  return (
    <>
      {!loading ? (
        <div>
          {displayData && displayData.length ? (
            displayData?.map((obj: any, index: any) => {
              const currentTime = new Date();

              const timestampMilliseconds = obj?.isNewUpto?.seconds * 1000 + obj?.isNewUpto?.nanoseconds / 1e6;
              const providedDateTime = new Date(timestampMilliseconds);
              if (sponsorData && sponsorData.length) {
                sponsorData.map((ads: any) =>
                  ads.item_name === obj.name
                    ? (obj = {
                      ...obj,
                      itemSponsorBannerUrl: ads.sponser_media,
                      sponser_url: ads.sponser_url,
                    })
                    : ""
                );
              }
              const items: any[] = itemData;
              const subCatItems: any[] = [];
              if (obj.category) {
                for (let i = 0; i < items.length; i++) {
                  if (
                    items[i]?.subCategories &&
                    items[i]?.subCategories?.length
                  ) {
                    if (items[i]?.subCategories.includes(obj.name)) {
                      subCatItems.push(items[i]);
                    }
                  }
                }
              }
              const mergedArray = subCatItems.map((item) => {
                const matchingItem = menuItemRatings.find((element: any) => element.id === item.id);
                return { ...item, ...matchingItem };
              });
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor: "white",
                    height: "auto",
                    marginBottom: "10px",
                    position: "relative",
                  }}
                >
                  <div
                    className="border-menu"
                    style={{ color: "#9cdde0" }}
                  ></div>
                  <div className="details-body">
                    <div className="details-body-header">
                      <h1 className="name-resData">{obj.name}</h1>
                    </div>
                    <div className="details-logo-section">
                      <img
                        src={data.resData.companyLogoImage ? data.resData.companyLogoImage
                          : images.IMG_DEFAULT_LOGO}
                        alt="icon"
                        className="details-img"
                      />
                    </div>
                    {providedDateTime > currentTime ? <div className="new_item">New</div> : ''}
                  </div>

                  <MenuItemSection
                    obj={obj}
                    data={data}
                    index={index}
                    sponsorData={sponsorData}
                    logoUrl={logoUrl}
                    subCatItems={mergedArray}
                    logoImageProps={logoImageProps} />
                </div>
              );
            })
          ) : (
            <div className="no-found-section">
              <img src={images.NO_FOUND} alt="icon" className="no-img" />
              <div className="no-tags-menu">
                Oops, We couldn't find a match!
              </div>
              <div className="no-tags-item">
                Try searching for something else
              </div>
              <div className="no-tags-go-back" onClick={() => navigate(-1)}>Back to {data?.resData?.name}</div>
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default MenuItem;

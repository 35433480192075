import React, { useState } from "react";
import { images } from "../../commonConfig";
import Footer from "../../components/molecules/Footer/Footer";
import HomeTabRestaurant from "./HomeTabRestaurant/HomeTabRestaurant";

import "./ManageHomepageTab.scss";

const HomepageTab = () => {
  const [searchString, setSearchString] = useState<any>();
  const handleSearch = (e: any) => {
    e.preventDefault();
    setSearchString(e.target.value);
  };
  return (
    <div>
      <div className="header-fix">
        <div className="Homepage-header-container">
          <div className="Homepage-image-container">
            <img alt="" src={images.HOME_LOGO} className="home-logo-homepage" />

            <div className="search-input">
              <div className="search-icon">
                <img alt="" src={images.IMG_SEARCH} className="search-icon-img" />
              </div>
              {/* <div className="backarrow-icon">
                <img
                  src={images.IMG_BACKARROW}
                  className="backArrow-icon-img"
                />
              </div> */}
              <input
                placeholder="Search Restaurant"
                type="text"
                id="search-bar"
                name="search-bar"
                value={searchString}
                onChange={handleSearch}
                className="input-searchbar"
              />
              {/* <button className="cross-icon btns">
                <img src={images.IMG_CROSS} className="cross-icon-img" />
              </button> */}
            </div>
          </div>
        </div>
        <div className="res-heading-home">
          <h2>What are you craving today?</h2>
        </div>
      </div>

      <HomeTabRestaurant searchValue={searchString} />
      <Footer />
    </div>
  );
};

export default HomepageTab;

export const Webmenu = {
    API:
        process.env.REACT_APP_ENV === "production"
            ? "https://webmenu.visueats.com/"
            : "https://dev-webmenu.visueats.com/",
}

export const Base = {
    API:
        process.env.REACT_APP_ENV === "production"
            ? "https://us-central1-prod-visueats.cloudfunctions.net"
            : "https://us-central1-dev-visueats.cloudfunctions.net",
};

export const EmailBase = {
    API:
        process.env.REACT_APP_ENV === "production"
            ? "https://admin.visueats.com"
            // : "http://localhost:3011"
            : "https://devadmin2.visueats.com",
};
import { db, storage } from "../firebase/firebase";
import { Base, EmailBase } from '../commonConfig'
import { months, weekDays } from "../commonConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import moment from "moment";
import { v4 as uuid } from 'uuid';
import html2pdf from "html2pdf.js";
import { getDownloadURL, ref, uploadString } from "firebase/storage";

export const getRestaurantByUniqueId = async (id: any) => {
    var restData: any;
    const restaurantsRef = await db.collection('Restaurant').get();
    const snapshotData = restaurantsRef.docs.map((doc: any) => {
        var data = { id: doc.id, ...doc.data() }
        return data;
    });
    snapshotData.map((res) => {
        if (res.uniqId == id) {
            restData = res
        }
    })
    return restData
}

export const getAllRestaurantAdmins = async (id: any) => {
    var resAdmins: any[] = [];
    await db.collection(`Restaurant/${id}/AdminUser`).get()
        .then((res) => res.docs.map((item) => {
            const user = item.data()
            if (user) {
                resAdmins.push(user)
            }
        }))
    return resAdmins
}

export const getAllRestaurantHosts = async (id: any) => {
    var resHosts: any[] = [];
    await db.collection(`Restaurant/${id}/HostUser`).get()
        .then((res) => res.docs.map((item) => {
            const user = item.data()
            if (user) {
                resHosts.push(user)
            }
        }))
    return resHosts
}

export const getReservationById = async (reserveId: any) => {
    var reserveData: any;
    await db.collection(`Reservation`).doc(reserveId).get()
        .then((res) => reserveData = res.data())
    return reserveData
}

export const setReservationFeedback = async (data: any) => {
    await db.collection('Feedback').doc(data.id).set(data)
        .then((res) => { })
        .catch((err) => console.log('err :>> ', err))
}

export const getFeedbackById = async (id: any) => {
    var feedbackData: any;
    await db
        .collection("Feedback")
        .where("restaurantId", "==", id)
        .get()
        .then(async (snapshot: any) => {
            feedbackData = snapshot.docs.map((doc: any) => {
                return { id: doc.id, ...doc.data() }
            });
        })
    return feedbackData
}

// -------------Email Trigger Functions-------------

// export const sendReservationMailToAdmin = async (admins: any, reservationData: any, restaurantData: any, hosts:any) => {

//     const date = new Date(reservationData.date);
//     const resDate = date.getDate()
//     const resYear = date.getFullYear()
//     const monthName = months[date.getMonth()]
//     const dayName = weekDays[date.getDay()] // Thu
//     const formatted = `${dayName}, ${monthName} ${resDate}, ${resYear}`

//     var link = `${EmailBase.API}/restaurant-dashboard`;
//     var reserve_name = `${reservationData.first_name} ${reservationData.last_name}`

//     if (admins && admins.length) {
//         for (let i = 0; i < admins?.length; i++) {
//             fetch(
//                 `${Base.API}/sendReservationMailToAdmin?link=${link}&res_name=${restaurantData.name}&user_email=${admins[i].user_email}&reserve_name=${reserve_name}&reserve_email=${reservationData.email}&reserve_phone=${reservationData.phone}&time=${reservationData.time}&members=${reservationData.members}&date=${formatted.toString()}&dietary=${reservationData.dietary.toString()}&occasion=${reservationData.occasion.toString()}&seating=${reservationData.seating_reserv}&deposit=${reservationData.deposit}&special_request=${reservationData.specialRequest}`,
//                 { method: "GET", headers: { "Content-Type": "application/json" } }
//             ).then(async res => await res.json())
//                 .then(async response => { })
//                 .catch(err => {
//                     console.log("Error: ", err);
//                 });
//         }
//     }

//     if (hosts && hosts.length) {
//         for (let i = 0; i < hosts?.length; i++) {
//             fetch(
//                 `${Base.API}/sendReservationMailToAdmin?link=${link}&res_name=${restaurantData.name}&user_email=${hosts[i].user_email}&reserve_name=${reserve_name}&reserve_email=${reservationData.email}&reserve_phone=${reservationData.phone}&time=${reservationData.time}&members=${reservationData.members}&date=${formatted.toString()}&dietary=${reservationData.dietary.toString()}&occasion=${reservationData.occasion.toString()}&seating=${reservationData.seating_reserv}&deposit=${reservationData.deposit}&special_request=${reservationData.specialRequest}`,
//                 { method: "GET", headers: { "Content-Type": "application/json" } }
//             ).then(async res => await res.json())
//                 .then(async response => { })
//                 .catch(err => {
//                     console.log("Error: ", err);
//                 });
//         }
//     }
// }
export const sendReservationMailToAdmin = async (admins: any, reservationData: any, restaurantData: any, hosts: any) => {
    const link = `${EmailBase.API}/restaurant-dashboard`;

    const sendMail = async (recipient: any) => {
        console.log(recipient)
        const date = new Date(reservationData.date);
        const resDate = date.getDate();
        const resYear = date.getFullYear();
        const monthName = months[date.getMonth()];
        const dayName = weekDays[date.getDay()];
        const formatted = `${dayName}, ${monthName} ${resDate}, ${resYear}`;

        const url = new URL(`${Base.API}/sendReservationMailToAdmin`);
        url.searchParams.append('link', link);
        url.searchParams.append('res_name', restaurantData.name ?? '');
        url.searchParams.append('user_email', recipient.user_email ?? '');
        url.searchParams.append('reserve_name', `${reservationData.first_name} ${reservationData.last_name}` ?? '');
        url.searchParams.append('reserve_email', reservationData.email ?? '');
        url.searchParams.append('reserve_phone', reservationData?.phone);
        url.searchParams.append('time', reservationData.time);
        url.searchParams.append('members', reservationData.members);
        url.searchParams.append('date', formatted.toString());
        url.searchParams.append('dietary', reservationData?.dietary?.toString() ?? '');
        url.searchParams.append('occasion', reservationData?.occasion?.toString() ?? '');
        url.searchParams.append('seating', reservationData.seating_reserv);
        url.searchParams.append('deposit', reservationData.deposit);
        url.searchParams.append('special_request', reservationData.specialRequest);

        try {
            const response = await fetch(url, { method: "GET", headers: { "Content-Type": "application/json" } });
            const data = await response.json();
        } catch (err) {
            console.log("Error: ", err);
        }
    };

    if (admins && admins.length) {
        for (let i = 0; i < admins?.length; i++) {
            await sendMail(admins[i]);
        }
    }

    if (hosts && hosts.length) {
        for (let i = 0; i < hosts?.length; i++) {
            await sendMail(hosts[i]);
        }
    }
}
// export const sendReservationMailToDiner = async (reservationID: any, reservationData: any, restaurantData: any, subject: any) => {
//     const date = new Date(reservationData.date);
//     const resDate = date.getDate()
//     const resYear = date.getFullYear()
//     const monthName = months[date.getMonth()]
//     const dayName = weekDays[date.getDay()] // Thu
//     const formatted = `${dayName}, ${monthName} ${resDate}, ${resYear}`;
//     // const subject = "You have a new reservation 🥳"

//     const res_address = `${restaurantData?.add1}, ${restaurantData?.city}, ${restaurantData?.state}, ${restaurantData?.country}`;
//     const restaurantId = restaurantData.id;

//     const edit_reservation = `${EmailBase.API}/edit-reservation?restId=${restaurantId}%26reserveId=${reservationID}`;
//     const add_to_calendar = `${EmailBase.API}/add-to-calendar?reserveId=${reservationID}`
//     const cancel_reservation = `${EmailBase.API}/cancel-reservation?restId=${restaurantId}%26reserveId=${reservationID}`;
//     const terms_conditions = `${EmailBase.API}/terms-conditions?restId=${restaurantId}`;

//     const url = new URL(`${Base.API}/sendReservationMailToDiner`);
//     if (reservationID !== undefined) {
//         url.searchParams.append('edit_reservation_link', edit_reservation);
//         url.searchParams.append('subject', subject);
//         url.searchParams.append('user_email', reservationData.email ?? '');
//         url.searchParams.append('add_to_calendar_link', add_to_calendar);
//         url.searchParams.append('cancel_reservation_link', cancel_reservation);
//         url.searchParams.append('first_name', reservationData.first_name ?? '');
//         url.searchParams.append('restaurant_name', restaurantData.name ?? '');
//         url.searchParams.append('restaurant_address', res_address);
//         url.searchParams.append('restaurant_phone', restaurantData.number);
//         url.searchParams.append('restaurant_id', restaurantData.id);
//         url.searchParams.append('reservation_id', reservationID);
//         url.searchParams.append('time', reservationData.time);
//         url.searchParams.append('members', reservationData.members);
//         url.searchParams.append('date', formatted.toString());
//         url.searchParams.append('terms_conditions', terms_conditions);
//         url.searchParams.append('dietary', reservationData?.dietary?.toString() ?? '');
//         url.searchParams.append('occasion', reservationData?.occasion?.toString() ?? '');
//         url.searchParams.append('seating', reservationData.seating_reserv ?? '');
//         url.searchParams.append('deposit', reservationData.deposit);
//         url.searchParams.append('special_request', reservationData.specialRequest);

//         await fetch(
//             // `${Base.API}/sendReservationMailToDiner?edit_reservation_link=${edit_reservation}&subject=${subject}&user_email=${reservationData.email}&add_to_calendar_link=${add_to_calendar}&cancel_reservation_link=${cancel_reservation}&first_name=${reservationData.first_name}&restaurant_name=${restaurantData?.name}&restaurant_address=${res_address}&restaurant_phone=${restaurantData?.number}&restaurant_id=${restaurantData.id}&reservation_id=${reservationID}&time=${reservationData.time}&members=${reservationData.members}&date=${formatted.toString()}&terms_conditions=${terms_conditions}&dietary=${reservationData.dietary.toString()}&occasion=${reservationData.occasion.toString()}&seating=${reservationData.seating_reserv}&deposit=${reservationData.deposit}&special_request=${reservationData.specialRequest}`,
//             url,
//             { method: "GET", headers: { "Content-Type": "application/json" } }
//         ).then(async res => await res.json())
//             .then(async response => { })
//             .catch(err => {
//                 console.log("Error: ", err);
//             });
//     }
// }

export const sendReservationMailToDiner = async (reservationID: any, reservationData: any, restaurantData: any, subject: any) => {
    const date = new Date(reservationData.date);
    const resDate = date.getDate()
    const resYear = date.getFullYear()
    const monthName = months[date.getMonth()]
    const dayName = weekDays[date.getDay()] // Thu
    const formatted = `${dayName}, ${monthName} ${resDate}, ${resYear}`;
    // const subject = "You have a new reservation 🥳"

    const res_address = `${restaurantData?.add1}, ${restaurantData?.city}, ${restaurantData?.state}, ${restaurantData?.country}`;
    const restaurantId = restaurantData.id;

    const edit_reservation = `${EmailBase.API}/edit-reservation?restId=${restaurantId}%26reserveId=${reservationID}`;
    const add_to_calendar = `${EmailBase.API}/add-to-calendar?reserveId=${reservationID}`
    const cancel_reservation = `${EmailBase.API}/cancel-reservation?restId=${restaurantId}%26reserveId=${reservationID}`;
    const terms_conditions = `${EmailBase.API}/terms-conditions?restId=${restaurantId}`;
    var totalAmount: any;
    var localTaxAmt: any;
    var serviceChargeAmt: any;
    var serviceFeeAmt: any;
    var currency = restaurantData?.currency !== undefined ? restaurantData?.currency : "USD";
    let preOrderAmt: any;
    let subTotalAmt: any;
    let preOrderList = reservationData?.preOrderList !== undefined ? reservationData.preOrderList : [];
    let pdfurl: any;

    // Calculate total amount
    if (reservationData.deposit !== '0' && reservationData.deposit !== undefined) {
        const depositAmt: any = reservationData.deposit !== undefined ? parseInt(reservationData.deposit).toFixed(2) : 0.00;
        preOrderAmt = 0;
        preOrderList?.map((item: any) => preOrderAmt += item?.modifiers?.total !== undefined ? parseFloat(item?.modifiers?.total) * parseInt(item.quantity) : (parseInt(item.quantity) * parseFloat(item.price)))
        subTotalAmt = preOrderAmt != 0 ? parseFloat(preOrderAmt) + parseFloat(depositAmt) : parseFloat(depositAmt);
        localTaxAmt = restaurantData.localTax && restaurantData.localTax !== undefined ? ((subTotalAmt * parseFloat(restaurantData.localTax)) / 100).toFixed(2) : '0.00';
        serviceChargeAmt = '0.00';
        serviceFeeAmt = restaurantData.currency == 'JMD' ? '117.00' : '0.75';

        if (preOrderAmt == 0 && depositAmt != 0) {
            preOrderAmt = parseFloat('0').toFixed(2);
            totalAmount = parseFloat(subTotalAmt) + parseFloat(localTaxAmt) + parseFloat(serviceChargeAmt) + parseFloat(serviceFeeAmt)
        } else if (parseFloat(preOrderAmt) > parseFloat(depositAmt)) {
            preOrderAmt = parseFloat(depositAmt).toFixed(2);
            totalAmount = (parseFloat(subTotalAmt) - parseFloat(depositAmt)) + (parseFloat(serviceFeeAmt) + parseFloat(localTaxAmt))
        } else if (parseFloat(preOrderAmt) <= parseFloat(depositAmt)) {
            preOrderAmt = parseFloat(preOrderAmt).toFixed(2);
            totalAmount = (parseFloat(subTotalAmt) - parseFloat(preOrderAmt)) + (parseFloat(serviceFeeAmt) + parseFloat(localTaxAmt))
        }
    }

    const url = new URL(`${Base.API}/sendReservationMailToDiner`);
    if (reservationID !== undefined) {
        url.searchParams.append('edit_reservation_link', edit_reservation);
        url.searchParams.append('subject', subject);
        url.searchParams.append('user_email', reservationData.email ?? '');
        url.searchParams.append('add_to_calendar_link', add_to_calendar);
        url.searchParams.append('cancel_reservation_link', cancel_reservation);
        url.searchParams.append('first_name', reservationData.first_name ?? '');
        url.searchParams.append('restaurant_name', restaurantData.name ?? '');
        url.searchParams.append('restaurant_address', res_address);
        url.searchParams.append('restaurant_phone', restaurantData.number);
        url.searchParams.append('restaurant_id', restaurantData.id);
        url.searchParams.append('reservation_id', reservationID);
        url.searchParams.append('time', reservationData.time);
        url.searchParams.append('members', reservationData.members);
        url.searchParams.append('date', formatted.toString());
        url.searchParams.append('terms_conditions', terms_conditions);
        url.searchParams.append('dietary', reservationData?.dietary?.toString() ?? '');
        url.searchParams.append('occasion', reservationData?.occasion?.toString() ?? '');
        url.searchParams.append('seating', reservationData.seating_reserv ?? '');
        url.searchParams.append('special_request', reservationData.specialRequest);
        url.searchParams.append('receipt_number', reservationData?.receipt_number);
        url.searchParams.append('source', reservationData.source);
        url.searchParams.append('status', reservationData?.status);
        url.searchParams.append('preOrderID', reservationData?.preOrderID);
        url.searchParams.append('currency', currency);
        url.searchParams.append('deposit', reservationData?.deposit);
        url.searchParams.append('subTotalAmt', subTotalAmt);
        url.searchParams.append('local_tax', localTaxAmt);
        url.searchParams.append('service_charge', serviceChargeAmt);
        url.searchParams.append('serviceFeeAmt', serviceFeeAmt);
        url.searchParams.append('preOrderAmt', preOrderAmt);
        url.searchParams.append('total_amount', totalAmount);
        url.searchParams.append('pdfurl', "");
        url.searchParams.append('userId', reservationData?.userId);

        await fetch(
            // `${Base.API}/sendReservationMailToDiner?edit_reservation_link=${edit_reservation}&subject=${subject}&user_email=${reservationData.email}&add_to_calendar_link=${add_to_calendar}&cancel_reservation_link=${cancel_reservation}&first_name=${reservationData.first_name}&restaurant_name=${restaurantData?.name}&restaurant_address=${res_address}&restaurant_phone=${restaurantData?.number}&restaurant_id=${restaurantData.id}&reservation_id=${reservationID}&time=${reservationData.time}&members=${reservationData.members}&date=${formatted.toString()}&terms_conditions=${terms_conditions}&dietary=${reservationData.dietary.toString()}&occasion=${reservationData.occasion.toString()}&seating=${reservationData.seating_reserv}&deposit=${reservationData.deposit}&special_request=${reservationData.specialRequest}&local_tax=${localTaxAmt}&service_charge=${serviceChargeAmt}&total_amount=${totalAmount}&receipt_number=${reservationData?.receipt_number}&source=${reservationData.source}&currency=${currency}&serviceFeeAmt=${serviceFeeAmt}`,
            url,
            { method: "GET", headers: { "Content-Type": "application/json" } }
        ).then(async res => await res.json())
            .then(async response => { })
            .catch(err => {
                console.log("Error: ", err);
            });
    }
}

// -------------SMS Text Function-------------

export const sentReservationSMSToDiner = async (reserveDetails: any, restroDetails: any, status: string) => {
    const formattedDate = moment(reserveDetails?.date).format('dddd, MMMM Do');
    var phoneNumber = reserveDetails.phone.replace("+", " ");
    var body = `Thanks for choosing visuEats ${reserveDetails?.first_name}! You have a new reservation 🥳 Reservation Details: ${restroDetails?.name} on ${reserveDetails.date} at ${reserveDetails?.time}.`
    if (reserveDetails.countryCode !== undefined) {
        phoneNumber = `${reserveDetails.countryCode}${reserveDetails.phone}`
        phoneNumber = phoneNumber?.replace("+", " ");
    } else {
        phoneNumber = reserveDetails.phone?.replace("+", " ");
    }
    const smsURL = `${Base.API}/sendReservationSMSToDiner?to=${phoneNumber}&body=${body}`
    await fetch(smsURL, { method: "GET" })
        .then((res) => {
            // console.log('res :>> ', res)
        })
        .catch(err => console.log('err :>> ', err))
}

export const sendReservationSMSToDinerPlivo = async (reserveDetails: any, restroDetails: any, status: string) => {
    const formattedDate = moment(reserveDetails?.date).format('dddd, MMMM Do');
    console.log(reserveDetails)
    console.log(restroDetails)
    var text: any;
    // var phoneNumber: any;
    if (reserveDetails.source !== "visuEats" && status === 'Pending') {
        text = `Thanks for choosing visuEats ${reserveDetails?.first_name}! You have a new reservation 🥳 Reservation Details: ${restroDetails?.name} on ${formattedDate} at ${reserveDetails?.time}.`
        console.log(text)
    }

    // For SMS
    var smsPhoneNumber: any;
    if (reserveDetails.countryCode !== undefined) {
        smsPhoneNumber = `${reserveDetails.countryCode}${reserveDetails.phone}`;
        smsPhoneNumber = smsPhoneNumber?.replace("+", " ");
    } else {
        smsPhoneNumber = reserveDetails.phone?.replace("+", " ");
    }

    const url = new URL(`${Base.API}/sendReservationSMSToDinerPlivo`)

    const body = {
        text: text,
        dst: smsPhoneNumber
    }

    try {
        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
        console.log('SMS sent successfully');
    } catch (error) {
        console.error('Error sending SMS:', error);
    }
}

export const sendReservationSMSToAdmin = async (reserveDetails: any, restroDetails: any, status: string) => {
    var phoneNumber: any;
    var link: any = `${EmailBase.API}/restaurant-dashboard`;
    var body = `🎊 You have a new reservation! 🥳 Please go to the visuEats admin panel to process this reservation. ${link} `

    if (restroDetails.countryCode !== undefined) {
        phoneNumber = `${restroDetails.countryCode}${restroDetails.number}`
        phoneNumber = phoneNumber?.replace("whatsapp:+", "").trim();
    } else {
        phoneNumber = restroDetails.number?.replace("whatsapp:+", "").trim();
    }

    const smsURL = `${Base.API}/sendReservationSMSToAdmin?to=${phoneNumber}&body=${body}`
    await fetch(smsURL, { method: "GET" })
        .then((res) => {
        })
        .catch(err => console.log('err :>> ', err))
}

export const getAllPreOrderItemByRestauarntID = async (restaurantID: any) => {
    var mergedPreOrderList: any = []
    await db.collection('PreOrder')
        .where('restaurantID', '==', restaurantID)
        .get()
        .then((res: any) => res.docs.map((item: any) => {
            var data = item.data()
            if (data.preOrderList.length) mergedPreOrderList.push(data.preOrderList)
        }))
    const flattenedPreOrderList = mergedPreOrderList.reduce((acc: any, arr: any) => acc.concat(arr), []);
    return flattenedPreOrderList
}

export const getAllOrderedItemsByRestaurantID = async (restaurantID: any) => {
    var mergedOrderList: any = []
    await db.collection('Orders')
        .where('restaurant_id', '==', restaurantID)
        .get()
        .then((res) => res.docs.map((item: any) => {
            var data = item.data()
            if (data.items.length) mergedOrderList.push(data.items)
        }))
    const flattenedOrderList = mergedOrderList.reduce((acc: any, arr: any) => acc.concat(arr), []);
    return flattenedOrderList
}
import React from 'react';
// import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

const InitializeGoogleAnalytics = () => {
    // ReactGA.initialize('G-YZTTFBNJX6');
    ReactGA4.initialize('G-YZTTFBNJX6');
}

const TrackGoogleAnalyticsEvent = (category: string, action: string, label: string) => {
  // console.log({
  //   "category": category,
  //   "action": action,
  //   "label": label,
  // })
    // ReactGA.event({
    //     category: category,
    //     action: action,
    //     label: label,
    //   });
      // Send GA4 Event
      ReactGA4.event({
        category: category,
        action: action,
        label: label,
      });
}

export default InitializeGoogleAnalytics;
export {InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent}
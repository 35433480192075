import React, { useState } from "react";

import { images } from "../../commonConfig";
import Footer from "../../components/molecules/Footer/Footer";
import HomepageRestaurant from "../../components/organisms/ManageHomepage/HomepageRestaurant";

import "./MainHomepage.scss";

const MainHomepage = () => {
  const [searchString, setSearchString] = useState<any>();
  const handleSearch = (e: any) => {
    e.preventDefault();
    setSearchString(e.target.value)
  }
  return (
    <div className="main-homepage-container">
      <div className="main-homepage">
        <div className="main-homepage-flex">
          <div style={{ marginTop: "-20px" }}>
            <img alt="#"
              src={images.IMG_VISUEATS_COLOR}
              className="visueats-color-image"
            />
          </div>
          <div>
            <div className="search-input-container">
              <div className="search-input">
                <div className="search-icon">
                  <img alt="#" src={images.IMG_SEARCH} className="search-icon-img" />
                </div>
                {/* <div className='backarrow-icon' >
                                         <img src={images.IMG_BACKARROW} className="backArrow-icon-img" />
                                         </div> */}
                <input
                  placeholder="Try searching “Thai”"
                  type="text"
                  id="search-bar"
                  name="search-bar"
                  value={searchString}
                  onChange={handleSearch}
                  className="input-searchbar"
                />
                {/* <button className='cross-icon btns'>
                                        <img src={images.IMG_CROSS} className="cross-icon-img" />
                                        </button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="restaurant-heading">
          <h2>What are you craving today?</h2>
        </div>

        <HomepageRestaurant searchValue={searchString} />
      </div>
      <Footer />
    </div>
  );
};

export default MainHomepage;
